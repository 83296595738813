<template>
  <main
    class="min-w-[500px] max-w-md sm:max-w-xl md:max-w-4xl lg:max-w-7xl mx-auto 4xl:tracking-widest"
    v-if="scoreCardData !== undefined && Object.keys(scoreCardData).length > 0"
  >
    <h1 class="text-red-500 block text-4xl my-4 font-semibold">
      Project Analysis
    </h1>
    <!-- Product Information -->
    <section id="product-information">
      <h2
        class="text-[rgb(43, 45, 66)] block text-2xl my-5 font-bold tracking-wider"
      >
        Information
      </h2>
      <div
        class="border p-3 border-black rounded-xl flex flex-col justify-between tracking-wider"
      >
        <ul class="no-underline block mb-0 list-none leading-7 px-0">
          <li class="flex justify-between">
            <span class="uppercase font-extrabold">ID</span>
            <span class="font-normal text-lg">{{ scoreCardData?.id }}</span>
          </li>
          <li class="flex justify-between">
            <span class="uppercase font-extrabold">NAME</span>
            <span class="font-normal text-lg">{{
              scoreCardData?.data?.repository?.name
            }}</span>
          </li>
          <li class="flex justify-between">
            <span class="uppercase font-extrabold">PLATFORM</span>
            <span class="font-normal text-lg">Open CoDE GitLab</span>
          </li>
          <li class="flex justify-between">
            <span class="uppercase font-extrabold"
              >REPOSITORY ID ON PLATFORM</span
            >
            <span class="font-normal text-lg">{{
              scoreCardData?.data?.repository?.repositoryId
            }}</span>
          </li>
          <li class="flex justify-between">
            <span class="uppercase font-extrabold">OPENCODE GITLAB</span>
            <span class="font-bold text-lg">{{
              scoreCardData?.data?.repository?.url
            }}</span>
          </li>
        </ul>
      </div>
    </section>

    <!-- Language Distribution -->
    <section id="language-distribution">
      <h2
        class="text-[rgb(43, 45, 66)] block text-2xl my-5 font-bold tracking-wider"
      >
        Language distribution
      </h2>
      <div
        class="border p-3 border-black rounded-xl flex flex-col justify-between tracking-wider"
      >
        <ul class="no-underline block mb-0 list-none leading-7 px-0">
          <li class="flex justify-between">
            <span class="uppercase font-extrabold">SWIFT</span>
            <span class="font-normal text-lg">57.54%</span>
          </li>
          <li class="flex justify-between">
            <span class="uppercase font-extrabold">OBJECTIVE-C</span>
            <span class="font-normal text-lg">41.13%</span>
          </li>
          <li class="flex justify-between">
            <span class="uppercase font-extrabold">HTML</span>
            <span class="font-normal text-lg">1%</span>
          </li>
          <li class="flex justify-between">
            <span class="uppercase font-extrabold">SHELL</span>
            <span class="font-normal text-lg">0.13%</span>
          </li>
          <li class="flex justify-between">
            <span class="uppercase font-extrabold">C</span>
            <span class="font-normal text-lg">0.11%</span>
          </li>
        </ul>
      </div>
    </section>

    <!-- Calculated KPI's -->
    <section id="calculated-kpi">
      <div class="my-6">
        <h2
          class="text-[rgb(43, 45, 66)] block text-2xl my-5 font-bold tracking-wider"
        >
          Calculated KPIs
        </h2>
        <div
          class="border p-3 border-black rounded-xl flex flex-col justify-between"
        >
          <button
            @click="toggleHidd('calculated-kpi-content', 'chevron-down-icon')"
            class="transform delay-100"
          >
            <div>
              <div class="flex justify-between" style="margin-block: 0px">
                <span>
                  <h3 class="m-0 font-bold mb-2">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="gauge-high"
                      class="hidden md:inline-block h-5 overflow-visible justify-start"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM288 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM256 416c35.3 0 64-28.7 64-64c0-17.4-6.9-33.1-18.1-44.6L366 161.7c5.3-12.1-.2-26.3-12.3-31.6s-26.3 .2-31.6 12.3L257.9 288c-.6 0-1.3 0-1.9 0c-35.3 0-64 28.7-64 64s28.7 64 64 64zM176 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM96 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm352-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
                      ></path>
                    </svg>
                    Project Score
                  </h3>
                </span>

                <span>
                  <span>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="triangle-exclamation"
                      class="inline-block h-4 overflow-visible justify-start m-0 hover:text-red-500"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
                      ></path>
                    </svg>
                    <span class="font-bold text-lg">
                      <span class="text-red-500 font-bold text-lg m-0">
                        {{ scoreCardData?.data?.kpis?.value }}
                      </span>
                      / 100
                    </span>
                    <span
                      ><svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-down"
                        class="inline-block h-4 overflow-visible justify-start"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        id="chevron-down-icon"
                      >
                        <path
                          fill="currentColor"
                          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                        ></path></svg
                    ></span>
                  </span>
                </span>
              </div>

              <div class="h-2 w-full bg-gray-200">
                <div
                  :style="{
                    width: `${scoreCardData?.data?.kpis?.value}%`
                  }"
                  class="bg-red-500 h-2"
                ></div>
              </div>
            </div>
          </button>

          <!--  -->
          <div class="hidden" id="calculated-kpi-content">
            <h6 class="font-semibold my-2">Description</h6>
            <p class="my-2">
              Assesses the project resp. the provided software in the aspects of
              maturity (based on quality, security and usability aspects) as
              well as development process.
            </p>

            <h6 class="font-semibold my-2">Calculation</h6>

            <!-- security score -->
            <div class="mb-6">
              <div class="flex gap-6">
                <div class="flex">
                  <span>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="plus"
                      class="h-4 overflow-visible justify-start"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                      ></path>
                    </svg>
                  </span>
                  <span class="hidden md:inline-block">Aggregation</span>
                </div>
                <div
                  class="border p-3 border-black rounded-xl flex flex-col flex-1 justify-between"
                  v-for="(item, index) in scorecardDetails[
                    this.$route.params.id
                  ]?.data?.kpis?.children"
                  :key="index"
                >
                  <button
                    @click="
                      toggleHidd(`secrets${index}`, `security-score${index}`)
                    "
                  >
                    <div class="flex justify-between" style="margin-block: 0px">
                      <span>
                        <h3 class="m-0 text-gray-300">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="xmark"
                            class="h-6 hidden md:inline-block overflow-visible justify-start"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path
                              fill="currentColor"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                            ></path>
                          </svg>
                          {{ item.kpi.name }}
                        </h3>
                      </span>

                      <span>
                        <span
                          ><svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="chevron-down"
                            class="inline-block h-4 overflow-visible justify-start"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            :id="'security-score' + index"
                          >
                            <path
                              fill="currentColor"
                              d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                            ></path></svg
                        ></span>
                      </span>
                    </div>
                  </button>

                  <!-- secrets -->
                  <div class="hidden" :id="'secrets' + index">
                    <h6 class="font-semibold my-2">Description</h6>
                    <p>
                      {{ item.kpi.description }}
                    </p>
                    <div class="my-3" v-if="item.kpi.children.length > 0">
                      <div class="flex gap-6">
                        <div class="flex">
                          <span>
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="plus"
                              class="h-4 overflow-visible justify-start"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentColor"
                                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                              ></path>
                            </svg>
                          </span>
                          <span class="hidden md:inline-block"
                            >Aggregation</span
                          >
                        </div>
                        <div
                          class="border p-3 border-black rounded-xl flex flex-col flex-1 justify-between"
                        >
                          <button
                            @click="
                              toggleHidd(
                                `security-score-secrets${index}`,
                                `secret${index}`
                              )
                            "
                          >
                            <div
                              class="flex justify-between"
                              style="margin-block: 0px"
                            >
                              <span>
                                <h3 class="m-0 text-gray-300">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="xmark"
                                    class="h-6 hidden md:inline-block overflow-visible justify-start"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                    ></path>
                                  </svg>
                                  Secrets
                                </h3>
                              </span>

                              <span>
                                <span
                                  ><svg
                                    :id="'secret' + index"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="chevron-down"
                                    class="inline-block h-4 overflow-visible justify-start"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                                    ></path></svg
                                ></span>
                              </span>
                            </div>
                          </button>
                          <div
                            class="hidden"
                            :id="'security-score-secrets' + index"
                          >
                            <h6 class="font-semibold my-2">Description</h6>
                            <p>
                              Used to look for suspicious strings in the
                              repository, which might indicate leaked passwords
                              or other secrets.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Checked in Binaries -->
                    <div class="my-3" v-if="item.kpi.children.length > 0">
                      <div class="flex gap-6">
                        <div class="flex">
                          <span>
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="plus"
                              class="h-4 overflow-visible justify-start"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentColor"
                                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                              ></path>
                            </svg>
                          </span>
                          <span class="hidden md:inline-block"
                            >Aggregation</span
                          >
                        </div>
                        <div
                          class="border p-3 border-black rounded-xl flex flex-col flex-1 justify-between"
                        >
                          <button
                            @click="
                              toggleHidd(
                                `security-score-checked-in-binaries${index}`,
                                `checked-in-binaries${index}`
                              )
                            "
                          >
                            <div
                              class="flex justify-between"
                              style="margin-block: 0px"
                            >
                              <span>
                                <h3 class="m-0 text-gray-300">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="xmark"
                                    class="h-6 hidden md:inline-block overflow-visible justify-start"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                    ></path>
                                  </svg>
                                  Checked in Binaries
                                </h3>
                              </span>

                              <span>
                                <span
                                  ><svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="chevron-down"
                                    class="inline-block h-4 overflow-visible justify-start"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    :id="'checked-in-binaries' + index"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                                    ></path></svg
                                ></span>
                              </span>
                            </div>
                          </button>
                          <div
                            class="hidden"
                            :id="'security-score-checked-in-binaries' + index"
                          >
                            <h6 class="font-semibold my-2">Description</h6>
                            <p>
                              Used to assess the compliance to the OpenCoDE
                              platform guidelines in regards of not checking in
                              binaries.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Maximal Dependency Vulnerability Score -->
                    <div class="my-3" v-if="item.kpi.children.length > 0">
                      <div class="flex gap-6">
                        <div class="flex">
                          <span>
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="plus"
                              class="h-4 overflow-visible justify-start"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentColor"
                                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                              ></path>
                            </svg>
                          </span>
                          <span class="hidden md:inline-block"
                            >Aggregation</span
                          >
                        </div>
                        <div
                          class="border p-3 border-black rounded-xl flex flex-col flex-1 justify-between"
                        >
                          <button
                            @click="
                              toggleHidd(
                                `security-score-max-depend-vulna${index}`,
                                `max-depend-vulnerability-score${index}`
                              )
                            "
                          >
                            <div
                              class="flex justify-between"
                              style="margin-block: 0px"
                            >
                              <span>
                                <h3 class="m-0 text-gray-300">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="xmark"
                                    class="h-6 hidden md:inline-block overflow-visible justify-start"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                    ></path>
                                  </svg>
                                  Maximal Dependency Vulnerability Score
                                </h3>
                              </span>

                              <span>
                                <span
                                  ><svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="chevron-down"
                                    class="inline-block h-4 overflow-visible justify-start"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    :id="
                                      'max-depend-vulnerability-score' + index
                                    "
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                                    ></path></svg
                                ></span>
                              </span>
                            </div>
                          </button>
                          <div
                            class="hidden"
                            :id="'security-score-max-depend-vulna' + index"
                          >
                            <h6 class="font-semibold my-2">Description</h6>
                            <p>
                              This score is calculated by the following formula:
                              100 - (max(CVSS score) * 10). Thus, a lower value
                              indicates a more critical vulnerability.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Analysis and Results -->
    <section id="analysis-results">
      <div class="my-6">
        <h2 class="text-[rgb(43, 45, 66)] block text-2xl my-5 font-bold">
          Executed Analysis Tools and their Results
        </h2>
        <div
          class="border p-3 border-black rounded-xl flex flex-col justify-between"
        >
          <button
            @click="toggleHidd('analysis-result-content', 'chevron-icon')"
          >
            <div>
              <div class="flex justify-between" style="margin-block: 0px">
                <span>
                  <span>
                    <h3
                      class="text-[rgb(43, 45, 66)] block text-lg my-2 font-bold"
                    >
                      Gitlab API
                    </h3>
                  </span>
                </span>
                <span>
                  <span
                    ><svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-down"
                      class="inline-block h-4 overflow-visible justify-start"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      id="chevron-icon"
                    >
                      <path
                        fill="currentColor"
                        d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                      ></path></svg
                  ></span>
                </span>
              </div>
            </div>
          </button>

          <div class="hidden" id="analysis-result-content">
            <h6 class="font-semibold my-2 text-sm">Description</h6>
            <p>
              The Gitlab API is used to derive information about, e.g., the
              project's compliance to OpenCoDE's platform rules.
            </p>
            <h6 class="font-semibold my-2 text-sm">Latest Run</h6>
            <p>7.11.2023 - 03:28:33</p>
            <h6 class="font-semibold my-2 text-sm">Relevant KPIs</h6>
            <ul class="list-disc mx-5 leading-5 text-base">
              <li>Process Transparency Score</li>
              <li>Process Compliance Score</li>
              <li>Number of Commits</li>
              <li>Number of Signed Commits</li>
              <li>Documentation</li>
            </ul>
            <h6 class="font-semibold my-2 text-sm">Findings</h6>
            <p>Findings are displayed directly in the KPI hierarchy.</p>
          </div>
        </div>
      </div>
    </section>
  </main>
  <div
    v-else
    class="flex flex-col font-poppins items-center justify-center mt-20 h-[50px] w-full p-4"
  >
    <h2>No score data found for this project</h2>
    <router-link
      :to="`/project/${this.$route.params.id}`"
      class="mt-2 text-blue-500 hover:underline"
      ><h3>Go back</h3></router-link
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  created() {},
  methods: {
    toggleHidd(elementId, svgId) {
      const element = document.getElementById(elementId)
      element.classList.toggle('hidden')

      const svgElement = document.getElementById(svgId)
      svgElement.classList.toggle(
        'rotate-180',
        !svgElement.classList.contains('rotate-180')
      )
      //   svgElement.classList.toggle('rotate-180')
    }
  },
  computed: {
    ...mapGetters({ scorecardDetails: 'projectPage/scorecardDetails' }),
    ...mapGetters({ authUser: 'users/authUser' }),
    scoreCardData() {
      return (
        this.scorecardDetails && this.scorecardDetails[this.$route.params.id]
      )
    }
  }
}
</script>

<style scoped>
span {
  line-height: 1.75rem;
}

p {
  font-size: 1.02rem;
  line-height: 1.25rem;
}

.rotate-0 {
  transform: rotate(0deg);
  transition: transform 0.6s ease;
}
.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.6s ease;
}
</style>
