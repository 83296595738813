<template>
  <nav
    class="flex flex-row items-center justify-between gap-x-2 text-xs md:text-14"
  >
    <button @click="setPage(page - 1)" :disabled="page === 0" class="prev">
      Zurück
    </button>
    <ul class="flex flex-row items-center justify-center gap-x-2">
      <abbr
        :title="`Page ${p + 1}`"
        v-for="p in showPages(pageLength, page)"
        :key="`page-${p}`"
      >
        <li @click="setPage(p)" :class="{ active: page === p }">{{ p + 1 }}</li>
      </abbr>
    </ul>
    <button
      @click="setPage(page + 1)"
      :disabled="page === pageLength - 1"
      class="next"
    >
      Weiter
    </button>
  </nav>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue'

const props = defineProps(['length', 'page', 'limit'])
const emit = defineEmits(['click'])

const pageLength = ref(Math.ceil(props.length / props.limit))

function setPage(page) {
  emit('click', page)
}

function showPages(length, page) {
  if (length > 5) {
    if ([0, 1, 2].includes(page)) {
      return [0, 1, 2, 3, 4]
    } else if ([length - 1, length - 2, length - 3].includes(page)) {
      return [length - 5, length - 4, length - 3, length - 2, length - 1]
    } else {
      return [page - 2, page - 1, page + 0, page + 1, page + 2]
    }
  } else {
    return Array.from({ length: pageLength.value }, (_, idx) => idx++)
  }
}
</script>

<style scoped lang="postcss">
li {
  @apply w-8 h-8 md:w-10 md:h-10;
}

button {
  @apply h-8 md:h-10 px-3 md:px-3;
  @apply disabled:bg-gray-400 disabled:shadow-none disabled:hover:shadow-none;
  @apply disabled:opacity-100 disabled:hover:opacity-100 disabled:cursor-default;
}

button.prev {
  @apply rounded-s-lg;
}
button.next {
  @apply rounded-e-lg;
}

li,
button {
  @apply flex items-center justify-center;
  @apply cursor-pointer rounded-sm;
  @apply shadow-md hover:shadow hover:opacity-90;
  @apply text-white bg-red-primary;
}

li.active {
  @apply bg-white text-red-primary border border-red-primary;
  @apply shadow-none hover:shadow-none cursor-default hover:opacity-100;
}
</style>
