<template>
  <div class="w-full flex flex-col gap-y-0 text-sm my-3">
    <button type="reset" @click.prevent="reset" :disabled="!tags?.length">
      Filter zurücksetzen
    </button>
    <ul v-for="filter in filters" :key="filter.name">
      <li>
        <ContentAccordion
          :id="filter.name"
          :title="filter.name"
          :default-open="filter.openByDefault"
          noHash
        >
          <div class="max-h-[240px] overflow-y-auto">
            <ul
              class="flex flex-col gap-y-1.5 ml-1.5"
              v-if="filter.name !== 'CVE Security'"
            >
              <li v-for="option in filter.options" :key="option.name">
                <label class="inline-flex items-start gap-x-2 cursor-pointer">
                  <input
                    type="checkbox"
                    ref="checkUpdatedBox"
                    :name="option.name"
                    :value="option.value"
                    v-model="tags"
                    @change="emit('change')"
                    class="mt-[3px]"
                  />
                  <span class="leading-5">{{ option.name }}</span>
                </label>
              </li>
            </ul>
            <ul v-else>
              <li v-for="option in filter.options" :key="option.name">
                <div class="mb-2" :set="setSelectedValue(option.value)">
                  <label
                    class="inline-flex items-start gap-x-2 cursor-pointer pl-2 pt-2"
                  >
                    <input
                      id="steps-range"
                      type="range"
                      min="0"
                      max="10"
                      step="1"
                      :name="option.name"
                      v-model="selectedValues"
                      class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 accent-red-500" /></label
                  >&nbsp; &nbsp;
                  <span
                    class="font-poppins bold mb-3 text-base text-gray-500 md:text-xl dark:text-gray-400"
                    >{{ selectedValues }}</span
                  >
                </div>
              </li>
            </ul>
          </div>
        </ContentAccordion>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, watch } from 'vue'
import ContentAccordion from '@/components/ContentAccordion.vue'
import { filters } from '@/services/filterList'

const props = defineProps(['modelValue', 'cveRange'])
const emit = defineEmits(['update:modelValue', 'change', 'update:cveRange'])

const reset = () => {
  tags.value = []
  emit('change')
}

const selectedValues = ref(props.cveRange ?? 0)

const tags = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

watch(selectedValues, (to) => {
  emit('update:cveRange', Number(to))
})

const setSelectedValue = (optionName) => (value) => {
  selectedValues.value[optionName] = value
}
</script>

<style scoped lang="postcss">
button[type='reset'] {
  @apply px-2 py-1 my-1;
  @apply border border-red-primary disabled:border-gray-400;
  @apply shadow-sm hover:shadow-md hover:opacity-75;
  @apply disabled:shadow-none disabled:hover:shadow-none disabled:hover:opacity-100;
}

.dropdown-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.dropdown-content::-webkit-scrollbar-track {
  background: #e4e6e8;
}

.dropdown-content::-webkit-scrollbar-thumb {
  background: #e64363;
}

.checkbox-label {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  cursor: pointer;
}

.checkbox-label input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  font: inherit;
  color: #2b2d40;
  width: 1.3em;
  height: 1.3em;
  border: 2px solid #2b2d40;
  border-radius: 1px;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

.checkbox-label input[type='checkbox']::before {
  content: '';
  width: 0.7em;
  height: 0.7em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #e64363;
}

.checkbox-label input[type='checkbox']:checked::before {
  transform: scale(1);
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.8s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
