<template>
  <div class="flex flex-initial">
    <img
      class="mt-[6px] ml-[2px] mr-[2px] h-[30px] w-auto"
      src="../../assets/icons/filter-filled-svgrepo-com.svg"
      @click="toggleNotification = !toggleNotification"
    />
    <span
      id="badge-dismiss-default"
      class="ml-4 mt-1.5 inline-flex items-center h-8 px-4 py-1.5 border-2 border-gray-400 text-gray-400 font-medium text-xs leading-tight rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
      v-for="selectedType in selectedNotificationTypes"
      :key="selectedType"
    >
      {{ notificationTypeTitle(selectedType) }}
      <button
        type="button"
        class="inline-flex items-center p-0.5 ml-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300"
        data-dismiss-target="#badge-dismiss-default"
        aria-label="Remove"
        @click="removeFilter(selectedType)"
      >
        <svg
          aria-hidden="true"
          class="w-3.5 h-3.5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span class="sr-only">Remove badge</span>
      </button>
    </span>
    <ul
      class="absolute mt-10 w-[220px] h-[200px] text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
      v-if="toggleNotification"
    >
      <li
        class="w-full border-gray-200 rounded-t-lg dark:border-gray-600 pt-[10px]"
        v-for="(type, index) in notificationTypes"
        :key="index"
      >
        <div class="flex items-center pl-0">
          <label class="form-control">
            <input
              id="vue-checkbox"
              type="checkbox"
              :value="type.id"
              :checked="selectedNotificationTypes.includes(type.id)"
              @change="onSelect($event)"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            />
            <span class="ml-[5px]">{{ type.name }}</span>
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'NotificationTypeFilter',
  props: [],
  components: {},
  data() {
    return {
      toggleNotification: false,
      selectedNotificationTypes: []
    }
  },
  created() {},
  methods: {
    onSelect(e) {
      if (e.target.checked) {
        this.selectedNotificationTypes.push(e.target.value)
      } else {
        const findIndex = this.selectedNotificationTypes.indexOf(e.target.value)
        if (findIndex > -1) {
          this.selectedNotificationTypes.splice(findIndex, 1)
        }
      }
      this.$emit('filterTypes', this.selectedNotificationTypes)
    },
    notificationTypeTitle(typeId) {
      const { name } = this.notificationTypes.find((nt) => nt.id === typeId)
      return name
    },
    removeFilter(typeId) {
      const findIndex = this.selectedNotificationTypes.indexOf(typeId)
      if (findIndex > -1) {
        this.selectedNotificationTypes.splice(findIndex, 1)
      }
      this.$emit('filterTypes', this.selectedNotificationTypes)
    }
  },
  computed: {
    ...mapGetters({
      notificationTypes: 'notification/notificationTypesPerUser'
    })
  }
}
</script>
