import axiosinstance from '../../../services/axiosInstance'
export const actions = {
  async getNotifications({ commit }) {
    try {
      const { data } = await axiosinstance.get('/notifications')
      commit('pushNotification', data)
    } catch (err) {
      console.log(err)
    }
  },
  async getNotificationsCount({ commit }) {
    try {
      const { data } = await axiosinstance.get('/notification-count')
      commit('updateNotificationCount', data.count)
    } catch (err) {
      console.log(err)
    }
  },
  async getUserNotifictaionSettings({ commit }, { projectId }) {
    try {
      const { data } = await axiosinstance.get(
        `/notification-types/${projectId}`
      )
      commit('insertNotificationTypes', data)
    } catch (err) {
      console.log(err)
    }
  },
  async getUserNotifictaionsType({ commit }) {
    try {
      const { data } = await axiosinstance.get('/notification-types')
      commit('insertNotificationTypesPerUser', data)
    } catch (err) {
      console.log(err)
    }
  },
  async updateUserNotifictaionSettings(
    { commit },
    { projectId, notificationTypeId, active }
  ) {
    try {
      await axiosinstance.post(`/notification-types/${projectId}`, {
        notificationTypeId,
        active
      })
    } catch (err) {
      console.log(err)
    }
  },
  async markNotifciationsRead({ commit }, { notificationIds }) {
    try {
      return await axiosinstance.post('/notifications/read', {
        ids: notificationIds
      })
    } catch (err) {
      console.log(err)
    }
  }
}
