import { toast } from 'vue3-toastify'
import axiosinstance from '../../../services/axiosInstance'

export const actions = {
  async getProjectList({ commit }, filters) {
    const payload = {
      searchText: filters?.searchText !== undefined ? filters?.searchText : '',
      searchTags:
        filters?.searchTags !== undefined
          ? filters?.searchTags.map((ele) => ele.value)
          : []
    }
    axiosinstance
      .post('/search', payload)
      .then((response) => {
        if (response.status === 200) {
          commit('projectList', response.data)
        }
      })
      .catch((err) => {
        commit('projectList', [])
        console.log(err)
      })
  },
  async getProjectDetailById({ commit }, id) {
    return axiosinstance
      .get(`/search/${id}`)
      .then((response) => {
        commit('projectInfo', response.data)
      })
      .catch((err) => {
        commit('projectInfo', [])
        console.log(err)
      })
  },
  async getFileTreeDetails({ commit }, payload) {
    return axiosinstance
      .get(`/search/${payload.id}/fileTree`)
      .then((response) => {
        response.data.id = payload.id
        commit('fileTree', response.data)
      })
      .catch((err) => {
        commit('fileTree', [])
        console.log(err)
      })
  },
  async getSubFileTreeList({ commit }, payload) {
    const query =
      payload.tagName !== 'Select Tag'
        ? `?path=${payload.path}&ref=${payload.tagName}`
        : `?path=${payload.path}`
    return axiosinstance
      .get(`/search/${payload.id}/fileTree${query}`)
      .then((response) => {
        response.data.id = payload.id
        response.data.path = payload.path
        commit('subFileTreeList', response.data)
      })
      .catch((err) => {
        commit('subFileTreeList', [])
        console.log(err)
      })
  },
  async getProjectDeatilOssCompilance({ commit }, id) {
    return axiosinstance
      .get(`/project/${id}/oss-result`)
      .then((response) => {
        response.data.id = id
        commit('ossCompilance', response.data)
      })
      .catch((err) => {
        commit('ossCompilance', [])
        console.log(err)
      })
  },
  async getProjectDeatilCVESecurity({ commit }, id) {
    return axiosinstance
      .get(`/project/${id}/cve-result`)
      .then((response) => {
        response.data.id = id
        commit('cveSecurity', response.data)
      })
      .catch((err) => {
        commit('cveSecurity', [])
        console.log(err)
      })
  },
  async getProjectTagList({ commit }, id) {
    return axiosinstance
      .get(`/search/${id}/tags`)
      .then((response) => {
        response.data.id = id
        commit('tagsList', response.data)
      })
      .catch((err) => {
        commit('tagsList', [])
        console.log(err)
      })
  },
  async getProjectDetailsByTag({ commit }, payload) {
    return axiosinstance
      .get(
        `/search/project-details/${payload.projectId}/tags?tag_name=${payload.tagName}`
      )
      .then((response) => {
        response.data.id = payload
        commit('tagData', response)
      })
      .catch((err) => {
        commit('tagData', [])
        console.log(err)
      })
  },
  async productiveAccessCall({ commit }, payload) {
    axiosinstance
      .post('/search/productiveUseAccess', payload)
      .then((response) => {
        if (response.data.code === 200) {
          commit('productiveAccess', response.data.data)
        } else {
          response.data.projectId = payload.projectId
          commit('productiveAccess', response.data)
        }
      })
      .catch((err) => {
        commit('productiveAccess', {})
        console.log(err)
      })
  },
  async useAndReviewsDataCall({ commit }, payload) {
    axiosinstance
      .post('/search/use-and-ratings', payload)
      .then((response) => {
        if (payload.key === 1 && response.data.code === 200) {
          commit('useRatingsData', response?.data?.data[0])
        } else if (
          (payload.key === 2 || payload.key === 3) &&
          response.data.code === 200
        ) {
          response.data.data.code = response.data.code
          commit('useRatingsData', response.data.data)
        } else {
          response.data.projectId = payload.projectId
          commit('useRatingsData', response.data)
        }
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          const validation = err.response?.data?.validation
          if (validation?.keys?.length) {
            const label = err.response?.data?.message.match(/"([^']+)"/)[1]
            toast.warning(
              `${label} ist leer/ungültig. Bitte überprüfen Sie Ihre Eingabe.`
            )
          }
        }

        if (err?.response?.status) {
          const respObject = {
            code: err?.response?.status,
            projectId: payload.projectId,
            message: err?.response?.data?.error
          }
          commit('useRatingsData', respObject)
        }
      })
  },
  async depGraphDataCall({ commit }, id) {
    try {
      const { data } = await axiosinstance.get(`/project/${id}/dep-graph`)
      commit('depGraphData', { id, data: data?.data })
    } catch (ex) {
      commit('depGraphData', { id, error: ex.message, stack: ex.stack })
      console.trace(ex)
    }
  },
  async getUsersListReviews({ commit }, payload) {
    try {
      const response = await axiosinstance.post(
        '/search/use-and-ratings/user-list',
        payload
      )
      if (response.data.code === 200) {
        response.data.projectId = payload.projectId
        commit('usersReviewList', response.data)
      } else {
        commit('usersReviewList', response.data)
      }
    } catch (ex) {
      commit('usersReviewList', [])
      console.trace(ex)
    }
  },
  async getBewertungen({ commit }, payload) {
    try {
      const { searchText, status, project, user, orderBy, order, page, limit } =
        payload

      const query = new URLSearchParams()

      query.set('limit', limit)
      query.set('page', page)

      if (searchText.trim() !== '') {
        query.set('search', searchText)
      }

      if (status.length) {
        query.set('status', status)
      }

      if (project.length) {
        query.set('projectId', project)
      }

      if (user.length) {
        query.set('userId', user)
      }

      if (orderBy) {
        query.set('orderBy', orderBy)
        query.set('order', order)
      }

      const urlPath = '/redaktion?' + query.toString()
      const {
        data: { data, code }
      } = await axiosinstance.get(urlPath)

      if (code === 200) {
        commit('insertBewertungen', data.list)
        commit('putBewertungenLength', Number(data.count))
      }
    } catch (ex) {
      console.trace(ex)
    }
  },
  async getBewertungenFilters({ commit }) {
    try {
      const {
        data: { data, code }
      } = await axiosinstance.get('/redaktion/filters')

      if (code === 200) {
        commit('putBewertungenFilters', data)
      }
    } catch (ex) {
      console.trace(ex)
    }
  },
  async getProjectScorecardDetails({ commit }, projectId) {
    try {
      const {
        data: { data, code }
      } = await axiosinstance.get(`/project/${projectId}/scorecard`)
      const obj = { projectId, code, data }
      if (code === 200) {
        commit('scorecardDetails', obj)
      } else {
        commit('scorecardDetails', {})
      }
    } catch (ex) {
      console.trace(ex)
    }
  }
}
