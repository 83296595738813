<template>
  <div>
    <Popover class="relative">
      <div class="h-min flex items-center relative">
        <div class="badge" v-show="notificationCount > 0">
          <BadgeComponent :value="notificationCount" />
        </div>
        <slot />
      </div>

      <transition name="fade">
        <PopoverPanel
          class="absolute rounded-lg z-10 px-4 py-8 mt-4 left-full bg-gray-light text-gray-dark min-w-min md:min-w-max max-w-xs lg:max-w-3xl -translate-x-full transform list-none shadow-lg"
          static
          v-if="isOpen"
        >
          <ul
            id="list"
            class="overflow-y-auto max-h-96 z-10 flex flex-col gap-y-2 font-poppins"
            aria-labelledby="dropdownUsersButton"
          >
            <div v-if="!notifications.length">
              <p class="warning">New Notification is not available!</p>
            </div>
            <li
              class="m-[10px] border-b-[2px] border-b-solid border-b-[#aaa8a8]"
              v-for="notification in notifications.filter((n) => !n.read)"
              :key="notification.id"
              v-else
            >
              <p class="text-[15px] font-bold">
                {{ notification.project.replaceAll(' ', '&nbsp;') }}
                <span class="text-xs"
                  >&nbsp;-&nbsp;{{
                    timeSince(notification.createdAt).replace(' ', '&nbsp;')
                  }}</span
                >
              </p>
              <p class="text-xs font-semibold text-right">
                &nbsp;-&nbsp;{{ notification.notificationTypeName }}
              </p>
            </li>
          </ul>
          <div
            class="flex flex-nowrap place-content-between m-2"
            v-if="notifications.length"
          >
            <div>
              <button
                type="button"
                class="button-font inline-block px-4 py-1.5 border-2 border-gray-400 font-medium text-xs leading-tight rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                @click="showAll()"
              >
                Alle zeigen
              </button>
            </div>
            <div>
              <button
                type="button"
                class="button-font inline-block px-4 py-1.5 border-2 border-gray-400 font-medium text-xs leading-tight rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                @click="markReadAll()"
                v-if="!readMarked"
              >
                Alle gelesen
              </button>

              <button
                type="button"
                class="button-font inline-block px-4 py-1.5 border-2 border-gray-400 font-medium text-xs leading-tight rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                v-if="readMarked"
              >
                <svg
                  aria-hidden="true"
                  class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                Alle gelesen
              </button>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { Popover, PopoverPanel } from '@headlessui/vue'
import { ref } from 'vue'
import BadgeComponent from './Notification/BadgeComponent.vue'

export default {
  name: 'NotificationDropdown',
  components: { Popover, PopoverPanel, BadgeComponent },
  props: ['isOpen'],
  watch: {
    $route(to, from) {
      this.getNotifications()
    }
  },
  data() {
    return {
      isNotificationToggle: false,
      readMarked: false,
      pollingIntervalId: ref(null)
    }
  },
  async mounted() {
    await this.getNotificationsCount()
    this.pollingIntervalId = setInterval(async() => {
      await this.getNotificationsCount()
    }, 20000)
  },
  unmounted() {
    clearInterval(this.pollingIntervalId)
  },
  computed: {
    ...mapGetters({ notificationCount: 'notification/notificationCount' }),
    ...mapGetters({ notifications: 'notification/notifications' })
  },
  methods: {
    ...mapActions({
      markNotifciationsRead: 'notification/markNotifciationsRead',
      getNotifications: 'notification/getNotifications',
      getNotificationsCount: 'notification/getNotificationsCount'
    }),
    toggleNotification() {
      this.isNotificationToggle = !this.isNotificationToggle
    },
    notificationBadgeCount() {
      if (Array.isArray(this.notifications)) {
        return this.notifications.filter((not) => !not.read).length
      } else {
        return 0
      }
    },
    timeSince(time) {
      const today = moment()
      const diff = today.diff(time, 'day')
      if (diff > 0) return moment(time).format('DD.MM.YY hh:mm')
      else return moment(time).fromNow()
    },
    showAll() {
      this.$router.push('/notifications')
      this.toggleNotification()
    },
    async markReadAll() {
      const unReadNotificationIds = this.notifications
        .filter((n) => !n.read)
        .map((n) => n.id)
      if (unReadNotificationIds.length) {
        this.readMarked = true
        await this.markNotifciationsRead({
          notificationIds: unReadNotificationIds
        })
        await this.getNotifications()
        await this.getNotificationsCount()
        this.readMarked = false
      }
    }
  }
}
</script>

<style scoped lang="postcss">
.badge {
  @apply absolute -top-2 -left-2 z-10;
  @apply w-5 rounded-full;
  @apply bg-red-900 opacity-80 text-gray-light;
  @apply text-xs font-thin;
}
</style>
