<template>
  <div>
    <header class="!min-w-min">
      <div>
        <h1>Accountverwaltung</h1>
        <h4 class="text-center sm:text-center font-poppins text-[20px]"></h4>
      </div>
    </header>

    <!-- Main content -->
    <LayoutContainer
      v-if="!!me?.email"
      class="flex flex-col py-6 gap-y-6 lg:py-8 md:flex-row md:gap-x-12 lg:gap-x-3"
    >
      <div class="lg:w-1/4 hidden lg:block"></div>
      <ul class="contact">
        <img src="../assets/icons/left-contact-icon.svg" />
        <li v-if="me.address">
          <img class="h-4" src="../assets/icons/location-pin-svgrepo-com.svg" />
          <div>
            {{ me.address }}
          </div>
        </li>
        <li>
          <img class="h-4" src="../assets/icons/user-profile-svgrepo-com.svg" />
          <div>
            <p>{{ me.username }}</p>
          </div>
        </li>
        <li v-if="me.email">
          <img class="h-4" src="../assets/icons/message-svgrepo-com.svg" />
          <div>{{ me.email }}</div>
        </li>
        <li v-if="me.phone">
          <img
            class="h-4"
            src="../assets/icons/telephone-free-svgrepo-com.svg"
          />
          <div>{{ me.phone }}</div>
        </li>
      </ul>
    </LayoutContainer>
    <LayoutContainer
      class="flex flex-col py-6 gap-y-6 lg:py-8 md:flex-row md:gap-x-12 lg:gap-x-3"
    >
      <div class="w-1/4 lg:block hidden sm:visible">
        <ul class="space-y-6 lg:sticky top-8 mt-5 text-right pr-4">
          <li class="cursor-pointer">
            <NavLink
              @click="open('ubersicht')"
              href="#ubersicht"
              text="Übersicht"
            />
          </li>
          <li class="cursor-pointer">
            <NavLink
              @click="open('cveSecurity')"
              href="#cveSecurity"
              text="CVE-Security"
            />
          </li>
          <li class="cursor-pointer">
            <NavLink
              @click="open('SBOM')"
              href="#SBOM"
              text="Software Bill of Materials"
            />
          </li>
          <li class="cursor-pointer" v-if="$user?.role === 'admin'">
            <NavLink
              @click="open('employees')"
              href="#employees"
              text="Mitarbeitende"
            />
          </li>
        </ul>
      </div>
      <div class="w-full lg:w-3/4">
        <!-- warning content -->
        <ContentAccordion
          title="Übersicht"
          id="ubersicht"
          ref="ubersicht"
          size="lg"
          @click.once="showUbersichtV2 = true"
        >
          <UbersichtAccountV2 v-if="showUbersichtV2" />
        </ContentAccordion>
        <ContentAccordion
          title="CVE-Security"
          id="cveSecurity"
          ref="cveSecurity"
          size="lg"
          href="#cveSecurity"
          @click.once="showCVESecurityV2 = true"
        >
          <CVESecurityV2 v-if="showCVESecurityV2" />
        </ContentAccordion>
        <ContentAccordion
          title="Software Bill of Materials"
          id="SBOM"
          ref="SBOM"
          size="lg"
          @click.once="showSoftwareBillMaterialsV2 = true"
        >
          <SoftwareBillMaterialsV2 v-if="showSoftwareBillMaterialsV2" />
        </ContentAccordion>
        <ContentAccordion
          title="Mitarbeitende"
          id="employees"
          ref="employees"
          size="lg"
          v-if="$user?.role === 'admin'"
          @click.once="showMitarbeitendeAccountV2 = true"
        >
          <MitarbeitendeAccountV2 v-if="showMitarbeitendeAccountV2" />
        </ContentAccordion>
      </div>
    </LayoutContainer>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import LayoutContainer from '@/components/LayoutContainer.vue'
import ContentAccordion from '@/components/ContentAccordion.vue'
import NavLink from '@/components/NavLink.vue'
import LoadingComp from '@/components/LoadingComp.vue'
import axiosinstance from '@/services/axiosInstance'
import { toast } from 'vue3-toastify'

const UbersichtAccountV2 = defineAsyncComponent({
  loader: () => import('../components/AccountManagement/UbersichtV2.vue'),
  loadingComponent: LoadingComp
})

const CVESecurityV2 = defineAsyncComponent({
  loader: () => import('../components/AccountManagement/cveSecurityV2.vue'),
  loadingComponent: LoadingComp
})

const SoftwareBillMaterialsV2 = defineAsyncComponent({
  loader: () =>
    import('../components/AccountManagement/SoftwareBillMaterialsV2.vue'),
  loadingComponent: LoadingComp
})

const MitarbeitendeAccountV2 = defineAsyncComponent({
  loader: () => import('../components/AccountManagement/MitarbeitendeV2.vue'),
  loadingComponent: LoadingComp
})

export default {
  name: 'AccountManagementV2',
  data() {
    return {
      showUbersichtV2: false,
      showMitarbeitendeAccountV2: false,
      showSoftwareBillMaterialsV2: false,
      showCVESecurityV2: false,
      me: this.$user
    }
  },
  components: {
    LayoutContainer,
    ContentAccordion,
    NavLink,
    UbersichtAccountV2,
    CVESecurityV2,
    SoftwareBillMaterialsV2,
    MitarbeitendeAccountV2
  },
  mounted() {
    const id = this.$route.hash?.replace('#', '')
    if (this.$refs[id]) {
      this.open(id)
    } else {
      window.scrollTo({ top: 0 })
    }
    this.checkSubscriptions()
  },
  methods: {
    open(ref) {
      this.$refs[ref].isOpen = true
      this.$refs[ref].$el.click()
      this.$router.replace({ hash: '#' + ref })
    },
    async checkSubscriptions() {
      const { status, data } = await axiosinstance.get(
        '/account-management/project-list'
      )

      if (status === 204) {
        toast.info('Bitte abonnieren Sie mindestens ein Projekt')
      }

      if (status === 500) {
        toast.error(data.message)
      }
    }
  }
}
</script>

<style scoped lang="postcss">
header {
  @apply flex flex-wrap items-center py-8 lg:py-16 bg-banner bg-gray-lighter bg-no-repeat bg-cover;
  @apply h-[200px] sm:h-[300px] md:h-[365px];
}

header > div {
  @apply mx-auto max-w-screen-xl;
}

header h1 {
  @apply text-center leading-[65px] font-poppinsbold font-black text-2xl sm:text-3xl md:text-4xl;
}

ul.contact {
  @apply w-min lg:w-3/4;
  @apply space-y-2;
}
ul.contact > img {
  @apply h-12;
}
ul.contact li {
  @apply text-left mx-2;
  @apply flex items-center gap-x-2;
}
ul.contact li > div {
  @apply text-gray-darker;
}
ul.contact li > img {
  @apply h-4 w-4 object-contain;
}
</style>
