import { createStore, createLogger } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import notification from './modules/notification'
import projectPage from './modules/projects'
import license from './modules/license'
import questionaries from './modules/questionaries'
import machbarkeitsnachweise from './modules/machbarkeitsnachweise'
import comments from './modules/comments'
import users from './modules/users'
import account from './modules/account'
const debug = process.env.NODE_ENV !== 'production'
export default createStore({
  modules: {
    notification,
    projectPage,
    license,
    questionaries,
    machbarkeitsnachweise,
    comments,
    users,
    account
  },
  strict: debug,
  // eslint-disable-next-line multiline-ternary
  plugins: debug
    ? [createLogger(), createPersistedState()]
    : [createPersistedState()]
})
