export const getters = {
  comments: (state, getters, rootState) => {
    return state.comments
  },
  commentsUsers: (state, getters, rootState) => {
    return state.commentsUser
  },
  page: (state, getters, rootState) => {
    return state.page
  },
  scroll: (state, getters, rootState) => {
    return state.scroll
  },
  loadingComment: (state, getters, rootState) => {
    return state.loadingComment
  },
  likeCounter: (state) => (id) => {
    console.log('likeCounter:::', id, state)
    if (state.likeCounter[id]) { return state.likeCounter[id] }
    return {
      likes: 0
    }
  }
}
