<template>
  <!-- <TheHeader /> -->
  <LayoutContainer class="flex flex-col md:flex-row md:gap-x-16 md:mt-6">
    <main role="main" class="flex-1 flex flex-col px-4 -mx-4 py-3">
      <div>
        <h1>Feature Toggle admin</h1>
      </div>

      <span @click="resetAllDefault()"><bold>Reset all to default</bold></span>
      <br />

      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>On/Off</th>
            <th>Reset</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(feature, i) of featureToggles" :key="i">
            <td :for="'ck' + feature">
              {{ feature }}
            </td>
            <td>
              <input
                v-model="features[feature]"
                @change="featureToggleChange($event)"
                :id="'ck' + feature"
                :name="feature"
                type="checkbox"
              />
            </td>
            <td>
              <!-- <input
                v-model="features[feature]"
                @change="featureToggleChange($event)"
                :id="'ck' + feature"
                :name="feature"
                type="checkbox"
              /> -->
              <button class="btn btn-link" @click="resetDefault(feature)">
                Reset to default
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div v-for="(feature, i) of featureToggles" :key="i">
        <label :for="'ck' + feature">{{ feature }}</label>
        <input
          v-model="features[feature]"
          @change="featureToggleChange($event)"
          :id="'ck' + feature"
          :name="feature"
          type="checkbox"
        />
        <span @click="resetDefault(feature)"
          ><bold> Reset to default </bold></span
        >
        <br />
      </div> -->
    </main>
  </LayoutContainer>
</template>

<script>
export default {
  name: 'FeatureToggles',
  components: {},
  data() {
    return {
      featureToggles: [
        'versioning',
        'reviews',
        'keycloak',
        'comments',
        'productiveUse',
        'notifications',
        'cveSecurityReportFilter',
        'fileTree',
        'ossCompilanceNoticeFile',
        'ossCompilanceObligationReportFilter',
        'ossCompilanceForYourUseCase',
        'ossCompilanceQuestionnaire',
        'componentList',
        'commentsV2',
        'accountManagement',
        'editorial',
        'machbarkeitsnachweise',
        'scorecardView'
      ],
      features: window.FEATURE_TOGGLES
    }
  },
  created() {},
  methods: {
    featureToggleChange(change) {
      console.log('entered featureToggleChange')
      const { checked, name } = change.target

      let ft = localStorage.getItem('featureToggles')
      if (!ft) {
        ft = '{}'
      }

      ft = JSON.parse(ft)

      ft[name] = checked

      localStorage.setItem('featureToggles', JSON.stringify(ft))
    },
    resetDefault(item) {
      console.log('entered resetDefault')
      let ft = localStorage.getItem('featureToggles')
      if (!ft) {
        ft = '{}'
      }

      ft = JSON.parse(ft)

      delete ft[item]
      localStorage.setItem('featureToggles', JSON.stringify(ft))
    },
    resetAllDefault() {
      localStorage.setItem('featureToggles', '{}')
      window.location.reload()
    }
  },
  computed: {}
}
</script>

<style></style>
