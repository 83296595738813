/* eslint-disable no-return-assign */

const recursiveFxn = (comments, comment, toggle) => {
  comments.forEach((c) => {
    if (c.id === comment.id) {
      c.readMore = toggle
    } else {
      if (c.reply.length) {
        recursiveFxn(c.reply, comment, toggle)
      }
    }
  })
}
const recursiveLikeFxn = (comments, commentId, type) => {
  comments.forEach((c) => {
    if (c.id === commentId) {
      if (type) {
        c.like = c.like > 0 ? c.like : 1
      } else {
        c.like = c.like > 0 ? 0 : c.like
      }
    } else {
      if (c.reply.length) {
        recursiveLikeFxn(c.reply, commentId, type)
      }
    }
  })
}
const recursiveEditFxn = (comments, comment) => {
  comments.forEach((c) => {
    if (c.id === comment.id) {
      c.comment = comment.comment
    } else {
      if (c.reply.length) {
        recursiveEditFxn(c.reply, comment)
      }
    }
  })
}

const recursiveReplyFxn = (comments, parentId, payload) => {
  comments.forEach((c) => {
    if (c.id === parentId) {
      const newComment = {
        ...payload,
        reply: [],
        readMore: payload.comment.toString().length > 150
      }
      c.reply.push(newComment)
    } else {
      if (c.reply.length) {
        recursiveReplyFxn(c.reply, parentId, payload)
      }
    }
  })
}
const recursiveDeleteFxn = (comments, commentId) => {
  const index = comments.findIndex((c) => c.id === commentId)
  if (index > -1) {
    comments.splice(index, 1)
  } else {
    if (comments.length) {
      comments.forEach((cc) => {
        if (cc.reply.length) {
          recursiveDeleteFxn(cc.reply, commentId)
        }
      })
    }
  }
}
export const mutations = {
  updateReply(state, payload) {
    const { parentId } = payload
    const comments = state.comments
    recursiveReplyFxn(comments, parentId, payload)
  },
  updateCommentLike(state, payload) {
    const { commentId, type } = payload
    const comments = state.comments
    recursiveLikeFxn(comments, commentId, type)
  },

  editComment(state, comment) {
    const comments = state.comments
    recursiveEditFxn(comments, comment)
  },

  deleteComment(state, commentId) {
    const comments = state.comments
    recursiveDeleteFxn(comments, commentId)
  },
  insertComments(state, comments) {
    state.commentsUser = comments.users
    if (comments.comments.length < 20) {
      state.scroll = false
    }
    state.comments.push(...comments.comments)
    state.loadingComment = false
  },
  updateComment(state, payload) {
    const comments = state.comments
    const { comment, toggle } = payload
    recursiveFxn(comments, comment, toggle)
  },
  addPage(state, page) {
    state.page = page
  },
  loadingComment(state, loading) {
    state.loadingComment = loading
  },
  resetCommentState(state, page) {
    state.page = 0
    state.comments = []
    state.commentsUser = []
    state.scroll = true
  },
  incremmentLikes(state, commentId) {
    console.log('state Before increment::', state)
    const projectLikeObj = state.likeCounter[commentId]
    if (projectLikeObj?.likes > 0) {
      projectLikeObj.likes++
      state.likeCounter[commentId] = projectLikeObj
    } else {
      state.likeCounter[commentId] = {
        likes: 1
      }
    }
    console.log('state After increment::', state)
  },
  decrementLikes(state, commentId) {
    console.log('state:: Before Decrement', state)
    const projectLikeObj = state.likeCounter[commentId]
    console.log(projectLikeObj)
    if (projectLikeObj.likes > 0) {
      projectLikeObj.likes--
      state.likeCounter[commentId] = projectLikeObj
    }
    console.log('state After Decrement::', state)
  }
}
