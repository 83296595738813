import axios from '../../../services/axiosInstance'
import { toast } from 'vue3-toastify'

export const actions = {
  async getAll({ commit }, payload) {
    try {
      const search = new URLSearchParams()
      search.set('limit', payload.limit)
      search.set('page', payload.page)

      if (payload.search?.trim().length > 0) {
        search.set('search', payload.search)
      }

      const {
        status,
        data: { data, length, message }
      } = await axios.get('/machbarkeitsnachweise?' + search.toString())

      if (status === 200) {
        commit('insertAllMachbarkeitsnachweise', { data, length })
      }

      if (status === 204) {
        commit('emptyMachbarkeitsnachweise')
      }

      if (status === 500) {
        toast.error(message)
      }
    } catch (ex) {
      console.error(ex.message)
    }
  }
}
