<template>
  <div>
    <LoadingComp
      v-if="$route.path.startsWith('/login_callback')"
      class="fixed w-screen h-screen flex items-center justify-center bg-gray-dark z-[999] bg-opacity-30"
    />
    <Disclosure
      as="nav"
      class="font-poppinsbold lg:border-b-8 border-b-gray-darkest bg-gray-darker"
      v-slot="{ open }"
    >
      <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div class="relative flex h-16 items-center justify-between">
          <div class="absolute inset-y-0 left-0 flex items-center lg:hidden">
            <!-- Mobile menu button-->
            <DisclosureButton
              class="relative inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-gray-darker hover:text-white focus:outline-none"
            >
              <span class="absolute -inset-0.5" />
              <span class="sr-only">Hauptmenü öffnen</span>
              <svg
                class="hamburger p-3 w-12 h-12"
                :class="{ 'is-opened': open }"
              >
                <line x1="0" y1="50%" x2="100%" y2="50%" class="bar--top" />
                <line x1="0" y1="50%" x2="100%" y2="50%" class="bar--mid" />
                <line x1="0" y1="50%" x2="100%" y2="50%" class="bar--bot" />
              </svg>
            </DisclosureButton>
          </div>
          <div
            class="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start h-full"
          >
            <router-link
              :to="{ name: 'DashboardView' }"
              class="flex flex-shrink-0 items-center gap-x-2"
            >
              <img
                class="h-8 w-auto"
                src="../../assets/images/quadratic_neg.png"
                alt="OpenCoDE Logo"
              />
              <span
                class="text-white hidden sm:block lg:hidden xl:block select-none"
                >Softwareverzeichnis</span
              >
            </router-link>
            <div class="hidden lg:ml-6 lg:flex flex-col">
              <ul class="flex h-full">
                <li class="h-full" v-for="item in navigation" :key="item.name">
                  <component
                    :is="item.href.startsWith('http') ? 'a' : 'router-link'"
                    v-if="!item.children?.length"
                    :target="item.newTab ? '_blank' : '_self'"
                    :to="item.href"
                    :href="item.href"
                    :class="[
                      item.href === route.path
                        ? 'bg-gray-darkest'
                        : 'hover:bg-gray-dark hover:text-white'
                    ]"
                    class="px-4 text-white font-medium h-full flex items-center transition-colors duration-200 ease-linear"
                    :aria-current="item.current ? 'page' : undefined"
                  >
                    {{ item.name }}
                  </component>
                </li>
                <AdminNav v-if="['editor', 'admin'].includes(user?.role)" />
              </ul>
            </div>
          </div>
          <div
            class="inset-y-0 right-0 flex items-center justify-center gap-x-3 pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
          >
            <Menu v-if="features.notifications && user?.id" v-slot="{ open }">
              <NotificationDropdown class="relative" :is-open="open">
                <MenuButton
                  type="button"
                  :class="{
                    'outline-none ring-2 ring-white ring-offset-2 ring-offset-gray-800':
                      open
                  }"
                  class="relative flex rounded-full bg-gray-800 text-sm"
                >
                  <span class="absolute -inset-1.5" />
                  <span class="sr-only">Benachrichtigungen anzeigen</span>
                  <img
                    src="../../assets/icons/notification-on-solid-svgrepo-com.svg"
                    class="w-8 h-8 p-1"
                  />
                </MenuButton>
              </NotificationDropdown>
            </Menu>

            <!-- Profile dropdown -->
            <Menu
              as="div"
              class="relative"
              v-if="features.accountManagement"
              v-slot="{ open, close }"
            >
              <div>
                <MenuButton
                  :class="{
                    'outline-none ring-2 ring-white ring-offset-2 ring-offset-gray-800':
                      open
                  }"
                  class="relative flex rounded-full bg-gray-800 text-sm"
                >
                  <span class="absolute -inset-1.5" />
                  <span class="sr-only">Benutzermenü öffnen</span>
                  <img
                    class="h-8 w-8 p-1 rounded-full"
                    src="../../assets/icons/user-profile-svgrepo-com.svg"
                    alt=""
                  />
                </MenuButton>
              </div>
              <transition name="fade">
                <MenuItems
                  as="ul"
                  class="list-none absolute right-0 z-10 mt-4 w-48 origin-top-right rounded-md bg-gray-light text-gray-dark py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem as="li" v-slot="{ active }" v-if="user?.id">
                    <router-link
                      @click="close"
                      :to="{ name: 'AccountManagement' }"
                      :class="{ 'bg-gray-lighter': active }"
                      class="block px-4 py-2 text-sm text-inherit"
                      >Accountverwaltung</router-link
                    >
                  </MenuItem>
                  <MenuItem as="li" v-slot="{ active }" v-if="user?.id">
                    <a
                      @click="logout"
                      :class="{ 'bg-gray-lighter': active }"
                      class="block px-4 py-2 text-sm text-inherit"
                      >Logout</a
                    >
                  </MenuItem>
                  <MenuItem as="li" v-slot="{ active }" v-if="!user?.id">
                    <a
                      @click="login"
                      :class="{ 'bg-gray-lighter': active }"
                      class="block px-4 py-2 text-sm text-inherit"
                      >Login</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <transition name="slide">
        <DisclosurePanel
          class="lg:hidden bg-gray-darker text-white origin-top"
          static
          v-if="open"
          v-slot="{ close }"
        >
          <div class="py-4">
            <DisclosureButton
              v-for="item in navigation"
              :key="item.name"
              :as="item.href.startsWith('http') ? 'a' : 'router-link'"
              :href="item.href"
              :to="item.href"
              :class="[item.current ? 'bg-gray-darkest' : 'hover:bg-gray-dark']"
              class="px-8 py-4 font-medium h-full flex text-inherit items-center transition-colors duration-200 ease-linear"
              :aria-current="item.current ? 'page' : undefined"
              >{{ item.name }}</DisclosureButton
            >
            <Menu
              v-if="['editor', 'admin'].includes(user?.role)"
              class="w-full"
              v-slot="{ open }"
              as="div"
            >
              <MenuButton class="px-8 py-4 w-full text-start">Admin</MenuButton>
              <transition name="slide">
                <MenuItems
                  as="ul"
                  static
                  v-show="open"
                  class="origin-top space-y-1 ms-4"
                >
                  <MenuItem
                    as="li"
                    v-if="features.editorial"
                    class="text-inherit"
                  >
                    <router-link
                      :to="{ name: 'EditorialDashboard' }"
                      @click="close"
                      class="py-4 px-8 font-medium h-full flex text-inherit"
                      >Redaktion</router-link
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </DisclosurePanel>
      </transition>
    </Disclosure>
  </div>
</template>

<script setup>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue'
import { login, logout } from '@/services/loginService.js'
import { onMounted, watch, ref } from 'vue'
import { useRoute } from 'vue-router'
import AdminNav from '@/components/AdminNav.vue'
import store from '@/store'
import axios from '@/services/axiosInstance'
import NotificationDropdown from '@/components/NotificationDropdown.vue'
import LoadingComp from '../LoadingComp.vue'
const route = useRoute()

const features = window.FEATURE_TOGGLES
const navigation = [
  { name: 'OpenCoDE', href: 'https://opencode.de/', newTab: true },
  { name: 'Software', href: '/' },
  { name: 'Wiki', href: 'https://wikijs.opencode.de', newTab: true },
  { name: 'Diskussion', href: 'https://discourse.opencode.de/', newTab: true },
  { name: 'Gitlab', href: 'https://gitlab.opencode.de/explore', newTab: true }
]

if (features.machbarkeitsnachweise) {
  navigation.push({
    name: 'Machbarkeitsnachweise',
    href: '/machbarkeitsnachweise'
  })
}

const user = ref(store.getters['users/authUser'])

onMounted(async() => {
  if (['/login_callback'].includes(window.location.pathname)) {
    const redirectUrl = localStorage.getItem('postLoginRedirect')
    if (redirectUrl) {
      localStorage.removeItem('postLoginRedirect')
    }
    const apiUrl =
      localStorage.getItem('oidcCallbackUrl') + window.location.search
    const res = await axios.get(apiUrl).catch(() => null)
    store.commit('users/authUser', res?.data?.userDetails ?? null)
    window.location = redirectUrl ?? '/'
  } else {
    const res = await axios.get('me').catch((er) => console.error(er.toJSON()))
    store.commit('users/authUser', res?.data?.userDetails || null)
    localStorage.removeItem('oidcCallbackUrl')
  }
})

watch(
  () => store.getters['users/authUser'],
  (to) => {
    user.value = to
  }
)
</script>

<style scoped lang="postcss">
.hamburger * {
  @apply transform duration-300 origin-center;
  @apply stroke-white stroke-[10%];
}
.bar--top {
  transform: translateY(-40%);
}
.bar--bot {
  transform: translateY(40%);
}
.is-opened .bar--top {
  transform: rotate(45deg);
}
.is-opened .bar--mid {
  transform: scaleX(0.1);
}
.is-opened .bar--bot {
  transform: rotate(-45deg);
}

nav a.router-link-exact-active,
nav button.router-link-exact-active {
  @apply !text-red-primary;
}
</style>
