<template>
  <div class="border-t-2 border-b-2 border-gray-light mb-[-2px]" :id="id">
    <button
      class="w-full text-left flex items-center justify-between cursor-pointer group focus:outline-none"
      :class="['text-sm sm:text-base md:text-lg lg:text-xl py-2 lg-py-4']"
      type="button"
      @click="toggle()"
    >
      <h4
        :text="title"
        class="!mb-0 leading-30 font-poppinsbold flex-1 group-hover:text-red-light group-hover:underline"
        :class="[isOpen ? 'text-red' : 'text-gunmetal']"
      >
        {{ title }}
      </h4>
      <div class="flex items-center">
        <img
          class="transform origin-center transition-transform"
          :class="[
            isOpen ? 'rotate-180' : 'rotate-0',
            size === 'md' ? 'w-4' : 'w-5'
          ]"
          src="/icons/chevron_down.svg"
          alt="Arrow Down"
        />
      </div>
    </button>

    <div
      class="grid transition-[grid-template-rows]"
      :class="[isOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]']"
    >
      <div
        class="min-h-0 transition-opacity overflow-hidden"
        :class="[isOpen ? 'opacity-100 pb-6' : 'opacity-0']"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
      isOpen: this.defaultOpen
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    defaultOpen: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md',
      validator: (size) => ['md', 'lg'].includes(size)
    },
    noHash: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapMutations({ resetCommentState: 'comments/resetCommentState' }),
    toggle() {
      this.isOpen = !this.isOpen
      if (!this.noHash) {
        this.$router.replace({ hash: '#' + this.id, query: this.$route.query })
      }
      return true
    }
  },
  watch: {
    isOpen(o, n) {
      if (!n && this.title === 'Comments') {
        this.resetCommentState()
        this.$emit('commentOpen', true)
      }
    }
  }
}
</script>
