<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="h-8 md:h-10 inline-flex w-full text-[10px] sm:text-base text-gunmetal items-center justify-center whitespace-nowrap rounded-full border-2 hover:border-gray-300 bg-white px-3 py-2 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-red focus-visible:ring-opacity-75"
      >
        {{ label }}

        <span
          class="-space-y-0.5 ml-6 -mr-1 text-[10px] sm:text-base"
          v-if="label != 'No tags available'"
        >
          <InlineSvg
            v-for="direction in ['down', 'up']"
            :key="direction"
            class="w-3 fill-red transform origin-center transition-transform"
            :class="[direction === 'down' ? 'rotate-180' : '']"
            src="/icons/chevron_down.svg"
            :alt="`Arrow ${direction}`"
            aria-hidden="true"
          />
        </span>
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
      class="absolute z-20 mt-1 w-full origin-top-right divide-y divide-gray-100 rounded-xl bg-white shadow-lg ring-2 ring-black ring-opacity-5 focus:outline-none overflow-y-auto max-h-60"
        :class="
          {
            left: 'left-1',
            right: 'right-0'
          }[menuAlignment]
        "
        v-if="label != 'No tags available'"
      >
        <div class="p-1 text-[10px] sm:text-base">
          <slot />
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import InlineSvg from 'vue-inline-svg'

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    InlineSvg
  },
  props: {
    label: {
      type: String,
      required: true
    },
    menuAlignment: {
      type: String,
      validator: (side) => ['left', 'right'].includes(side),
      default: 'left'
    }
  }
}
</script>
