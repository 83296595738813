const { default: Axios } = require('axios')

const axiosinstance = Axios.create({
  baseURL: window.API || 'http://localhost:5000',
  withCredentials: true
})

axiosinstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

export default axiosinstance
