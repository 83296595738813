<template>
  <!-- <TheHeader /> -->
  <LayoutContainer
    class="flex flex-col lg:flex-row lg:gap-x-6 lg:mt-6 justify-center"
  >
    <!-- Left Side Bar -->
    <nav class="w-full md:px-16 lg:px-2 lg:w-2/6">
      <SideBarV2
        @change="updateFilter"
        ref="checkboxes"
        v-model="tags"
        v-model:cveRange="cve"
      />
    </nav>
    <!-- Content Area -->
    <main
      role="main"
      class="flex-1 flex flex-col px-4 py-3 w-full lg:w-4/6 mx-auto"
    >
      <div class="flex flex-wrap gap-4 mb-6 justify-end md:justify-center">
        <!-- Search bar  -->
        <input
          type="text"
          v-model="searchText"
          name="search-bar"
          autocomplete="off"
          @input="searchTextChange"
          placeholder="Suche..."
          class="flex-1 h-8 md:h-10 hover:border-gray-300 inset-0 border-2 rounded-full px-4 focus:outline-0 focus:border-gray-400"
        />

        <SimpleDropdown label="Sortieren nach:" menu-alignment="right">
          <MenuItem>
            <div
              class="flex items-center justify-between text-gray-900 p-2 text-sm"
            >
              Auf-/Absteigend

              <TheSwitch v-model="orderBy" @click="getOrderValue(orderBy)" />
            </div>
          </MenuItem>
          <MenuItem
            v-for="(option, i) in [
              { label: 'Titel', value: 'title' },
              { label: 'Letzter Release', value: 'updatedAt' }
              /* { label: 'Bewertung', value: 'Bewertung' },
              {
                label: 'Produktiv-Einsatz Nutzer',
                value: 'Produktiv-Einsatz Nutzer'
              } */
            ]"
            :key="i"
            v-slot="{ active }"
          >
            <label
              class="cursor-pointer text-gray-900 flex justify-between w-full items-center rounded-md p-2 text-sm hover:bg-gray-light"
              :class="[active ? 'bg-gray-light' : '']"
            >
              {{ option.label }}

              <input
                v-model="orderByCol"
                :value="option.value"
                type="radio"
                name="sorting"
                @change="getSortColumn(option.value)"
                class="hidden peer"
              />

              <span class="checkmark ml-6 invisible peer-checked:visible" />
            </label>
          </MenuItem>
        </SimpleDropdown>
      </div>

      <!-- Selected filters -->
      <div class="mb-4">
        <ul class="flex flex-wrap gap-2 mb-3">
          <template
            v-for="(values, filter) in {
              categories,
              platformTags,
              devStatus,
              legal
            }"
          >
            <li v-for="item in values" :key="item">
              <button
                class="text-xs whitespace-nowrap py-1 px-3 rounded-full border flex-none bg-white hover:bg-gray-light"
                @click="removeFilter(item, filter)"
              >
                {{ filters.find((filter) => filter.value === item)?.name }}
                <span class="ml-1 -mr-0.5 text-red">&#10005;</span>
              </button>
            </li>
          </template>
        </ul>
      </div>

      <div class="flex flex-wrap -mx-4 relative">
        <div
          v-if="loading"
          class="absolute w-full h-full flex justify-center items-center z-10 bg-white"
        >
          <LoadingComp
            svg-class="text-gray-light fill-red-primary dark:text-gray-light dark:fill-gray-dark"
          />
        </div>
        <div
          class="w-full sm:w-1/2 md:w-1/2 xl:w-1/3 p-4"
          v-for="project in projects"
          :key="project.id + '+' + project.title"
        >
          <transition
            appear
            :key="project.id"
            enter-active-class="transition delay-100 duration-100 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0"
          >
            <ProjectTile
              :key="project.title"
              :id="project.id"
              :logo="project.logo"
              :title="project.title"
              :short-description-d="project.shortDescriptionD"
              :legal="project.legal"
            />
          </transition>
        </div>
      </div>
      <TransitionRoot
        :show="!!projects.length"
        appear
        key="if-projects"
        as="template"
        enter="transform transition duration-[500ms]"
        enter-from="opacity-0 scale-50"
        enter-to="opacity-100 scale-100"
        leave="transform duration-200 transition ease-in-out"
        leave-from="opacity-100 scale-100 "
        leave-to="opacity-0 scale-95"
      >
        <DynamicPagination
          :page="page"
          :length="length"
          :limit="limit"
          :key="`pagination-${length}-page-${page}`"
          @click="showPage"
        />
      </TransitionRoot>
      <TransitionRoot
        :show="!projects.length"
        appear
        key="else-no-projects"
        as="template"
        enter="transform transition duration-[500ms]"
        enter-from="opacity-0 scale-50"
        enter-to="opacity-100 scale-100"
        leave="transform duration-200 transition ease-in-out"
        leave-from="opacity-100 scale-100 "
        leave-to="opacity-0 scale-95"
      >
        <div class="warning">Keine Projekte verfügbar</div>
      </TransitionRoot>
    </main>
  </LayoutContainer>
</template>

<script>
import LayoutContainer from '../components/LayoutContainer.vue'
import ProjectTile from '../components/ProjectTile.vue'
import SideBarV2 from '../layouts/SideBarV2.vue'
import { filters, validateFilters } from '@/services/filterList'
import TheSwitch from '../components/TheSwitch.vue'
import SimpleDropdown from '@/components/SimpleDropdown.vue'
import { MenuItem, TransitionRoot } from '@headlessui/vue'
import { ref } from 'vue'
import axios from '@/services/axiosInstance'
import { toast } from 'vue3-toastify'
import DynamicPagination from '@/components/Pagination/DynamicPagination.vue'
import debounce from 'lodash.debounce'
import LoadingComp from '@/components/LoadingComp.vue'

const defaultsQuery = {
  search: '',
  sortBy: 'updatedAt',
  sort: 'desc',
  page: 1,
  cve: 0
}

export default {
  name: 'DashboardViewV2',
  components: {
    LayoutContainer,
    ProjectTile,
    SideBarV2,
    SimpleDropdown,
    MenuItem,
    TheSwitch,
    TransitionRoot,
    DynamicPagination,
    LoadingComp
  },
  watch: {
    $route(to, from) {
      this.checkError()
    },
    cve(to) {
      this.cveDebounce(to)
    }
  },
  data() {
    const query = this.$route.query
    const {
      search = defaultsQuery.search,
      sort = defaultsQuery.sort,
      sortBy = defaultsQuery.sortBy,
      page = defaultsQuery.page,
      cve = defaultsQuery.cve
    } = query

    const legal = query?.legal?.split(',') ?? []
    const categories = query?.categories?.split(',') ?? []
    const devStatus = query?.devStatus?.split(',') ?? []
    const platformTags = query?.platformTags?.split(',') ?? []

    const tags = [...legal, ...categories, ...devStatus, ...platformTags]

    return {
      tags: ref(tags),
      loading: ref(true),
      filters: filters.map(({ options }) => options).flat(),
      length: ref(0),
      projects: ref([]),
      cve: ref(Number(cve)),
      limit: 9,
      page: ref(page ? Number(page) - 1 : 0),
      searchText: ref(search),
      orderBy: ref(sort === 'desc'),
      orderByCol: ref(sortBy),
      sortingFilters: ref({
        sort: sort ?? 'asc',
        sortBy: sortBy
      }),
      legal: ref(legal),
      devStatus: ref(devStatus),
      categories: ref(categories),
      platformTags: ref(platformTags)
    }
  },
  async mounted() {
    if (validateFilters(this)) {
      isNaN(this.page) ? this.showPage(0) : await this.getProjectList()
    } else {
      toast.error('Ungültige Anfrage. Bitte Filter zurücksetzen')
    }
    this.checkError()
  },
  methods: {
    async getProjectList() {
      const search = new URLSearchParams()
      search.set('limit', this.limit)
      search.set('page', this.page)
      search.set('order', this.sortingFilters.sort)
      search.set('orderBy', this.sortingFilters.sortBy)

      if (this.legal.length) {
        search.set('legal', this.legal)
      }

      if (this.devStatus.length) {
        search.set('devStatus', this.devStatus)
      }

      if (this.categories.length) {
        search.set('categories', this.categories)
      }

      if (this.platformTags.length) {
        search.set('platformTags', this.platformTags)
      }

      if (![null, undefined, ''].includes(this.searchText)) {
        search.set('search', this.searchText)
      }

      if (this.cve !== 0) {
        search.set('cveScore', this.cve)
      }

      try {
        const {
          data: { code, data, message, length }
        } = await axios
          .get('/dashboard/project-list?' + search.toString())
          .finally(() => {
            this.loading = false
          })

        if (code !== 200) {
          toast.error('Etwas ist schief gelaufen: ' + message)
          return
        }

        if (data.length === 0 && this.page !== 0) {
          this.showPage(0)
          return
        }

        this.projects = data
        if (![null, undefined].includes(length)) {
          this.length = Number(length)
        }
      } catch (ex) {
        if (ex.response?.status) {
          console.error(ex.response.data.message)
          toast.error(ex.response.data.error, { autoClose: 5000 })
          return
        }

        console.error(ex)
        toast.error('Etwas ist schief gelaufen')
      }
    },
    getOrderValue(value) {
      this.sortingFilters.sort = value ? 'asc' : 'desc'
      this.updateSearchQuery('sort', value ? 'asc' : 'desc', this.resetList)
    },
    getSortColumn(value) {
      this.sortingFilters.sortBy = value
      this.updateSearchQuery('sortBy', value, this.resetList)
    },
    resetList() {
      this.showPage(0)
    },
    cveDebounce: debounce(
      function() {
        this.updateSearchQuery('cve', this.cve, this.resetList)
      },
      500,
      { trailing: true }
    ),
    searchDebounce: debounce(
      function() {
        this.resetList()
      },
      500,
      { trailing: true }
    ),
    searchTextChange(_) {
      this.updateSearchQuery('search', this.searchText, this.searchDebounce)
    },
    showPage(page) {
      this.loading = true
      this.page = page
      this.updateSearchQuery('page', page + 1, this.getProjectList)
    },
    /**
     * @param {Object} param0
     * @param {Function} callback */
    updateUrl(
      { query = this.$route.query, hash = this.$route.hash },
      callback
    ) {
      this.$router.replace({ query, hash }).then(callback)
    },
    updateSearchQuery(key, value, callback) {
      const query = Object.assign({}, this.$route.query)

      if (
        [null, undefined, '', 0].includes(value) ||
        defaultsQuery[key] === value
      ) {
        delete query[key]
      } else {
        query[key] = value
      }

      this.updateUrl({ query }, callback)
    },
    resetFilters() {
      this.devStatus = []
      this.legal = []
      this.platformTags = []
      this.categories = []
    },
    updateFilter() {
      this.resetFilters()

      this.tags.forEach((tag) => {
        if (
          [
            'compliant',
            'uncompliant',
            'componentUncompliant',
            'componentUnchecked'
          ].includes(tag)
        ) {
          this.legal.push(tag)
        } else if (tag.startsWith('.platforms-')) {
          this.platformTags.push(tag.toLowerCase())
        } else if (tag.startsWith('.developmentstatus-')) {
          this.devStatus.push(tag.toLowerCase())
        } else if (tag.startsWith('.categories-')) {
          this.categories.push(tag.toLowerCase())
        }
      })

      this.updateFilterQuery()
    },
    updateFilterQuery() {
      const query = Object.assign({}, this.$route.query)

      query.legal = this.legal.length ? this.legal.toString() : undefined
      query.devStatus = this.devStatus.length
        ? this.devStatus.toString()
        : undefined
      query.categories = this.categories.length
        ? this.categories.toString()
        : undefined
      query.platformTags = this.platformTags.length
        ? this.platformTags.toString()
        : undefined

      for (const key in query) {
        if (Object.hasOwnProperty.call(query, key)) {
          const value = query[key]
          if (value === undefined) {
            delete query[key]
          }
        }
      }

      this.updateUrl({ query }, this.resetList)
    },
    removeFilter(item, filter) {
      this[filter] = this[filter].filter((filter) => filter !== item)
      this.tags = this.tags.filter((filter) => filter !== item)
      this.updateFilterQuery()
    },
    checkError() {
      if (this.$route.query.error) {
        const error = this.$route.query.error
        toast.error(error, {
          delay: 1000,
          autoClose: 10000,
          onClose: () => {
            const query = Object.assign({}, this.$route.query)
            const hash = this.$route.hash

            delete query.error
            this.$router.replace({ query, hash })
          }
        })
      }
    }
  }
}
</script>

<style lang="postcss">
.error-message {
  @apply flex items-center;
  @apply px-8 py-4 m-4;
  @apply divide-x-[12px] divide-white;
  @apply rounded shadow;
  @apply text-icon;
}
</style>
