if (process.env.VUE_APP_FEATURE_TOGGLES) {
  window.FEATURE_TOGGLES = {
    ...window.FEATURE_TOGGLES,
    ...process.env.VUE_APP_FEATURE_TOGGLES.split(',').reduce((o, x) => {
      o[x] = true
      return o
    }, {})
  }
}

;(() => {
  let ft = localStorage.getItem('featureToggles')

  if (ft) {
    ft = JSON.parse(ft)
    window.FEATURE_TOGGLES = {
      ...window.FEATURE_TOGGLES,
      ...ft
    }
  }
})()

if (process.env.VUE_APP_API) {
  window.API = process.env.VUE_APP_API
}

export default function() {}
