import axiosinstance from '../../../services/axiosInstance'
// const userId = '31d9f4f3-3b0b-4438-9c66-5bfff3fd34be' // TEST PURPOSE ONLY
export const actions = {
  async getQuestions({ commit }, payload) {
    const { projectId } = payload
    try {
      const { data } = await axiosinstance.get(`/project/${projectId}/question`)
      commit('insertQuestions', data.questions)
    } catch (err) {
      console.log(err)
    }
  },

  async saveAnswer({ commit }, payload) {
    const { projectId, questionId, answeredId } = payload
    try {
      await axiosinstance.post(`/project/${projectId}/question`, {
        questionId,
        answeredId
      })
    } catch (err) {
      console.log(err)
    }
  }
}
