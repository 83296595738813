export const getters = {
  notifications: (state, getters, rootState) => {
    return state.notifications
  },
  notificationTypes: (state, getters, rootState) => {
    return state.notificationTypes
  },
  notificationTypesPerUser: (state, getters, rootState) => {
    return state.notificationTypesPerUser
  },
  notificationCount: (state, getters, rootState) => {
    return state.notificationCount
  }
}
