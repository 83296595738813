<template>
  <div>
    <section class="bg-slate-300 dark:bg-gray-900">
      <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
        <h1
          class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white"
        >
          Benachrichtigungs-Center
        </h1>
      </div>
    </section>
    <div class="container mx-auto p-4">
      <div class="flex">
        <div class="flex-initial w-1/3">
          <h2 class="font-extrabold">
            Neue Benachrichtigungen Ihrer abonnierten Projekte
          </h2>
        </div>
        <NotificationTypeFilter @filterTypes="onNewNotificationFilter" />
      </div>
      <div class="h-52 overflow-y-scroll">
        <div
          class="flex divide-y-2 py-2.5"
          v-for="notification in newNotifications"
          :key="notification.id"
        >
          <div class="flex-initial"></div>
          <div class="flex-initial w-1/4">
            <b>{{ notification.project }}</b>
            <span class="text-xs"
              >– {{ timeSince(notification.createdAt) }}</span
            >
            <p>{{ notification.notificationTypeName }}</p>
          </div>
          <div
            class="flex-initial w-1/2 px-4"
            @click="onMessageClick(notification)"
          >
            <p v-html="notification.message"></p>
          </div>
          <div class="flex-initial w-1/5 pt-4 text-center">
            <button
              type="button"
              class="button-font inline-block px-4 py-1.5 border-2 border-gray-400 text-gray-400 font-medium text-xs leading-tight rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              @click="onMessageClick(notification)"
            >
              Anzeigen
            </button>
          </div>
        </div>
      </div>
      <div class="justify-center mt-1 h-[50px] w-full flex items-center pt-10">
        <PaginationComponent
          :pageData="totalNewNotifications"
          :perPage="pageSize"
          @paginatedItems="paginatedNewNotifications"
        />
      </div>
    </div>
    <div class="container mx-auto p-4 pt-9">
      <div class="flex">
        <div class="flex-initial w-1/3">
          <h2 class="font-bold">Archiv aller Benachrichtigungen</h2>
        </div>
        <NotificationTypeFilter @filterTypes="onArchiveNotificationFilter" />
      </div>
      <div class="h-52 overflow-y-scroll">
        <div
          class="flex divide-y-2 py-2.5"
          v-for="notification in archiveNotifications"
          :key="notification.id"
        >
          <div class="flex-initial"></div>
          <div class="flex-initial w-1/4">
            <b>{{ notification.project }}</b>
            <span class="text-xs"
              >– {{ timeSince(notification.createdAt) }}</span
            >
            <p>{{ notification.notificationTypeName }}</p>
          </div>
          <div class="flex-initial w-1/2 px-4">
            <p v-html="notification.message"></p>
          </div>
          <div class="flex-initial w-1/5 pt-4 text-center">
            <button
              type="button"
              class="button-font inline-block px-4 py-1.5 border-2 border-gray-400 text-gray-400 font-medium text-xs leading-tight rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              @click="onMessageClick(notification)"
            >
              Anzeigen
            </button>
          </div>
        </div>
      </div>
      <div class="justify-center mt-1 h-[50px] w-full flex items-center pt-10">
        <PaginationComponent
          :pageData="totalArchiveNotifications"
          :perPage="pageSize"
          @paginatedItems="paginatedArchiveNotifications"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import PaginationComponent from './Pagination/PaginationComponent.vue'
import NotificationTypeFilter from './Helper/NotificationTypeFilter.vue'
export default {
  name: 'NotificationCenter',
  components: { PaginationComponent, NotificationTypeFilter },
  computed: {
    ...mapGetters({ notifications: 'notification/notifications' }),
    ...mapGetters({
      notificationTypes: 'notification/notificationTypesPerUser'
    })
  },
  data() {
    return {
      pageSize: 3,
      totalArchiveNotifications: [],
      totalNewNotifications: [],
      archiveNotifications: [],
      newNotifications: []
    }
  },
  created() {
    this.getUserNotifictaionsType()
    this.totalNewNotifications = this.notifications.filter((n) => !n.read)
    this.totalArchiveNotifications = this.notifications.filter((n) => n.read)
  },
  methods: {
    ...mapActions({
      markNotifciationsRead: 'notification/markNotifciationsRead',
      getNotifications: 'notification/getNotifications',
      getUserNotifictaionsType: 'notification/getUserNotifictaionsType',
      getNotificationsCount: 'notification/getNotificationsCount'
    }),
    timeSince(time) {
      const today = moment()
      const diff = today.diff(time, 'day')
      if (diff > 0) return moment(time).format('DD.MM.YY hh:mm')
      else return moment(time).fromNow()
    },
    paginatedArchiveNotifications(list) {
      this.archiveNotifications = list
    },
    paginatedNewNotifications(list) {
      this.newNotifications = list
    },
    async markRead(id) {
      if (id) {
        await this.markNotifciationsRead({
          notificationIds: [id]
        })
        await this.getNotificationsCount()
        await this.getNotifications()
        this.totalNewNotifications = this.notifications.filter((n) => !n.read)
        this.totalArchiveNotifications = this.notifications.filter(
          (n) => n.read
        )
      }
    },
    onNewNotificationFilter(types) {
      if (types.length) {
        const totalNotifications = this.notifications.filter((n) => !n.read)
        const filteredTotalNotifications = totalNotifications.filter((tn) =>
          types.includes(tn.notificationTypeId)
        )
        this.totalNewNotifications = filteredTotalNotifications
      } else {
        this.totalNewNotifications = this.notifications.filter((n) => !n.read)
      }
    },
    onArchiveNotificationFilter(types) {
      if (types.length) {
        const totalNotifications = this.notifications.filter((n) => n.read)
        const filteredTotalNotifications = totalNotifications.filter((tn) =>
          types.includes(tn.notificationTypeId)
        )
        this.totalArchiveNotifications = filteredTotalNotifications
      } else {
        this.totalArchiveNotifications = this.notifications.filter(
          (n) => n.read
        )
      }
    },
    onMessageClick(notification) {
      this.markRead(notification.id)
      if (parseInt(notification.notificationTypeId) === 2) {
        const link = `/project/${notification.entityId}#cve-security`
        this.$router.push(link)
      }
      if (parseInt(notification.notificationTypeId) === 4) {
        const link = `/project/${notification.entityId}#comments`
        this.$router.push(link)
      }
      if (parseInt(notification.notificationTypeId) === 1) {
        const link = `/project/${notification.entityId}`
        this.$router.push(link)
      }
      if (parseInt(notification.notificationTypeId) === 3) {
        const link = notification.links[0]
        if (link) {
          window.open(link, '_blank')
        }
      }
    }
  }
}
</script>
