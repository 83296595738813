<template>
  <div
    v-cloak
    class="py-8 lg:py-16 bg-gray-lighter bg-no-repeat bg-cover flex items-center min-h-[240px] animate-pulse"
    style="background-image: url('/img/BG-Icons.svg')"
  >
    <LayoutContainer
      class="flex flex-col gap-y-6 md:flex-row md:gap-x-12 lg:gap-x-3"
    >
      <div class="lg:w-1/4">
        <div
          class="w-48 md:w-52 p-4 lg:p-6 aspect-video bg-gray-light rounded-lg flex items-center justify-center"
        >
          <img
            class="max-w-full mix-blend-multiply bg-gray-100 object-cover object-center flex-shrink-0 rounded-full"
          />
        </div>
      </div>
      <div class="flex-grow">
        <h1
          class="font-poppinsbold font-black text-3xl md:text-4xl title-font w-full bg-gray-400 rounded-lg p-3"
        ></h1>
      </div>
    </LayoutContainer>
  </div>
  <LayoutContainer
    class="flex flex-col py-6 gap-y-6 lg:py-8 md:flex-row md:gap-x-12 lg:gap-x-3 animate-pulse"
  >
    <div class="w-1/4 hidden lg:block">
      <ul class="space-y-6 lg:sticky top-8">
        <li class="cursor-pointer">
          <h2
            class="title-font font-medium w-full bg-gray-400 rounded-lg p-3"
          ></h2>
        </li>
        <li class="cursor-pointer">
          <h2
            class="title-font font-medium w-full bg-gray-400 rounded-lg p-3"
          ></h2>
        </li>
        <li class="cursor-pointer">
          <h2
            class="title-font font-medium w-full bg-gray-400 rounded-lg p-3"
          ></h2>
        </li>
        <li class="cursor-pointer">
          <h2
            class="title-font font-medium w-full bg-gray-400 rounded-lg p-3"
          ></h2>
        </li>
        <li class="cursor-pointer">
          <h2
            class="title-font font-medium w-full bg-gray-400 rounded-lg p-3"
          ></h2>
        </li>
        <li class="cursor-pointer">
          <h2
            class="title-font font-medium w-full bg-gray-400 rounded-lg p-3"
          ></h2>
        </li>
      </ul>
    </div>

    <div class="w-full lg:w-3/4">
      <p class="bg-gray-400 rounded-lg p-7 mb-8"></p>

      <p class="bg-gray-400 rounded-lg p-7 mb-8"></p>
      <p class="bg-gray-400 rounded-lg p-7 mb-8"></p>
      <p class="bg-gray-400 rounded-lg p-7 mb-8"></p>
      <p class="bg-gray-400 rounded-lg p-7 mb-8"></p>
      <p class="bg-gray-400 rounded-lg p-7 mb-8"></p>
    </div>
  </LayoutContainer>
</template>
<script>
import LayoutContainer from '@/components/LayoutContainer.vue'
export default {
  components: {
    LayoutContainer
  }
}
</script>
<style scoped>
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
