<template>
  <router-link
    :to="`/project/${id}`"
    class="bg-white shadow-md flex flex-col rounded-md"
  >
    <div
      class="h-32 p-8 bg-gray-lighter bg-no-repeat bg-cover flex items-center justify-center relative"
      style="background-image: url('/img/BG-Icons.svg')"
    >
      <div class="w-full h-full bg-center">
        <div
          v-on:mouseenter="isTooltipVisible = true"
          v-on:mouseleave="isTooltipVisible = false"
          class="absolute left-[10px] top-[10px]"
        >
          <img
            class="doubleTick"
            :src="projectLegal.image"
            width="20"
            height="20"
          />
        </div>
        <ToolTip
          v-show="isTooltipVisible"
          :customClass="projectLegal.tooltipClass"
          >{{ projectLegal.tooltipText }}</ToolTip
        >
        <ProjectLogo :imageUrl="projectLogo" :image-alt="projectTitle" />
      </div>
    </div>
    <div class="p-4 min-h-[12rem] flex-1 flex flex-col">
      <h3
        class="text-[14px] leading-5 font-poppinsbold border-b-2 border-red pb-2 mb-1.5"
      >
        <span class="card-title">{{ projectTitle }}</span>
      </h3>
      <p
        class="text-sm text-gray-darker line-clamp-3 mb-4 w-full"
        v-html="shortDescriptionD"
      ></p>
      <div class="mt-auto">
        <RatingInfo
          v-if="FEATURE_TOGGLES.ProjectListRatting"
          :rating="4.2"
          :ratings="23"
        />
      </div>
    </div>
  </router-link>
</template>

<script>
import RatingInfo from '@/components/RatingInfo.vue'
import ToolTip from './Tooltip.vue'
import ProjectLogo from './ProjectDetails/ProjectLogo.vue'

export default {
  name: 'ProjectTile',
  components: {
    RatingInfo,
    ToolTip,
    ProjectLogo
  },
  props: {
    id: {
      type: String,
      required: true
    },
    logo: {
      type: String
    },
    title: {
      type: String,
      default: ''
    },
    longDescriptionD: {
      type: String,
      default: ''
    },
    shortDescriptionD: {
      type: String,
      default: ''
    },
    legal: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      FEATURE_TOGGLES: window.FEATURE_TOGGLES,
      isTooltipVisible: false
    }
  },
  computed: {
    projectTitle: function() {
      if (this.title) {
        return this.title.length > 24
          ? this.title.substring(0, 24) + '..'
          : this.title
      }
      return `Projekt #${this.id}`
    },
    projectLogo: function() {
      return this.logo
    },

    projectLegal: function() {
      const images = require.context('../assets/icons', false, /\.png$/)
      const toolTipObject =
        this.legal === 'compliant'
          ? {
              image: 'double-tick',
              tooltipText:
                'Dieses Projekt und die zur Verwendung benötigten Komponenten deklarieren ausschließlich akzeptierte Lizenzen.',
              tooltipClass: '-top-[30px] -left-[156px]'
            }
          : {
              image: 'warning-exclamation',
              tooltipText:
                'Das Projekt ist nicht lizenziert oder es wurde eine durch Open CoDE nicht akzeptierte Lizenz angegeben.',
              tooltipClass: '-top-[30px] -left-[156px] tickwidth'
            }
      toolTipObject.image = images('./' + toolTipObject.image + '.png')
      return toolTipObject
    }
  }
}
</script>

<style>
.tickwidth {
  width: 320px;
}

.doubleTick {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.text-container {
  display: inline-block;
  vertical-align: middle;
}

.card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
