<template>
  <a
    :href="href"
    :class="[
      'font-poppinsbold font-bold whitespace-nowrap text-gray-darkest',
      'border-b-4 border-transparent hover:border-red-light',
      'transition duration-300 ease-out',
      { 'border-b-red': isActive }
    ]"
  >
    {{ text }}
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    isActive() {
      return this.$route.hash === this.href
    }
  }
}
</script>
