import axios from '../../../services/axiosInstance'
import { toast } from 'vue3-toastify'

export const actions = {
  async getProjects({ commit }) {
    try {
      const {
        status,
        data: { data, message }
      } = await axios.get('/account-management/project-list')

      if (status === 200) {
        data.forEach((project) => commit('insertProject', project))
      }

      if (status === 204) {
        toast.error(message)
        commit('emptyProject')
      }

      if (status === 500) {
        toast.error(message)
      }
    } catch (ex) {
      console.error(ex.message)
    }
  }
}
