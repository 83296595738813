export const state = () => ({
  projectData: {},
  ossCompilance: {},
  ossCompilanceData: {},
  cveSecurity: {},
  cveSecurityData: {},
  tagsData: {},
  tagData: {},
  projectList: [],
  fileTree: {},
  depGraph: {},
  bewertungen: [],
  bewertungenLength: 0,
  bewertungenFilters: {}
})
