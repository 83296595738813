import axiosinstance from '../../../services/axiosInstance'
const API = window.API || 'http://localhost:5000'
const API_URL = `${API}/license`
const LIMIT = 10
export const actions = {
  async getLicenses({ commit }) {
    let results = []
    let lastCreatedAt = null
    let lastId = null
    let hasNextPage = true
    while (hasNextPage) {
      try {
        const url = new URL(API_URL)
        url.searchParams.set('limit', LIMIT)

        if (lastCreatedAt) {
          url.searchParams.set('createdAt', lastCreatedAt)
        }

        if (lastId) {
          url.searchParams.set('lastId', lastId)
        }

        const { data } = await axiosinstance.get(url)
        const res = data
        results = [...results, ...res.data]
        hasNextPage = res.data.length === LIMIT
        lastCreatedAt = res.data[res.data.length - 1]?.createdAt
        lastId = res.data[res.data.length - 1]?.id
      } catch (err) {
        console.log(err)
        break
      }
    }

    commit('licenses', results)
  },
  async getLicenseObligations({ commit }, licenseIds) {
    try {
      const result = await axiosinstance.get(
        `/license/ids?ids=${licenseIds.toString()}`
      )
      commit('licenseObligations', result?.data?.data)
    } catch (err) {
      console.log(err)
    }
  }
}
