import axios from 'axios'
import axiosinstance from '@/services/axiosInstance'
import { toast } from 'vue3-toastify'
const userId = '5f3cafe6-ef17-44b2-9b73-20474be9e478'
export const actions = {
  async getCurrentUser({ commit }) {
    try {
      const { data: res } = await axiosinstance.get(`/user/${userId}`)

      if (res.code === 200) {
        commit('insertMe', res.data)
      }
    } catch (err) {
      console.log(err)
    }
  },
  async updateNoticeFileDownload({ commit }, projectId) {
    try {
      const { data: res } = await axiosinstance.get(
        `/project/${projectId}/update-notice-file-download`
      )
      console.log({ res })
      // if (res.code === 200) {
      //   commit('insertMe', res.data)
      // }
      return res
    } catch (err) {
      console.log(err)
    }
  },
  async getUsers({ commit }, payload) {
    try {
      const searchParams = new URLSearchParams()
      searchParams.set('limit', payload.limit || 10)

      if (payload.lastId && payload.lastCreatedAt) {
        searchParams.set('lastId', payload.lastId)
        searchParams.set('lastCreatedAt', payload.lastCreatedAt)
      }

      const fullPath = 'user?' + searchParams.toString()
      const { data } = await axiosinstance.get(fullPath)

      if (data.code === 200) {
        commit('setAnonymous', !!data.isAnonymous)
        if (data.isAnonymous) {
          commit('emptyUserList')
        }
        if (data.data.length) {
          commit('insertUsers', data)
        } else if (payload.lastId && payload.lastCreatedAt) {
          toast.success('Keine weiteren Mitarbeiter verfügbar')
        }
      }
    } catch (err) {
      console.log(err)
    }
  },

  async getUserDetails({ commit }, payload) {
    axiosinstance
      .post('/user-details', payload)
      .then((response) => {
        commit('userDetails', response.data)
      })
      .catch((err) => {
        commit('userDetails', [])
        console.log(err)
      })
  },

  async addNewUser({ commit }, email) {
    try {
      const { data, status } = await axiosinstance.post('/user', { email })
      if (status === 201) {
        commit('insertUser', data.data)
      }
    } catch (ex) {
      console.error(
        'axios error',
        axios.isAxiosError(ex),
        ex.name,
        ex.message,
        ex.stack
      )
      toast.error(
        'Etwas ist schief gelaufen. Mitarbeiter konnte nicht hinzugefügt werden'
      )
    }
  }
}
