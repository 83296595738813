<template>
  <Switch v-model="model" as="template" v-slot="{ checked }">
    <button
      class="relative inline-flex h-6 w-11 items-center rounded-full"
      :class="checked ? 'bg-green-500' : 'bg-gray-200'"
    >
      <span
        :class="checked ? 'translate-x-6' : 'translate-x-1'"
        class="inline-block h-4 w-4 transform rounded-full bg-white transition"
      />
    </button>
  </Switch>
</template>

<script setup>
import { Switch } from '@headlessui/vue'
import { computed, defineProps, defineEmits } from 'vue'

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
</script>
