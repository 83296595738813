import axiosinstance from '../../../services/axiosInstance'
export const actions = {
  async getComments({ commit }, payload) {
    const { projectId, page = 0 } = payload
    try {
      const { data } = await axiosinstance.post(
        `project/${projectId}/comments/20/${page}`,
        payload
      )
      commit('insertComments', data.comments)
    } catch (err) {
      console.log(err)
    }
  },
  async editComment({ commit }, payload) {
    const { projectId, comment, commentId } = payload
    try {
      const { data } = await axiosinstance.put(
        `/project/${projectId}/comment/${commentId}`,
        {
          comment
        }
      )
      commit('editComment', data.comments)
    } catch (err) {
      console.log(err)
    }
  },
  async saveComment({ commit }, payload) {
    const { projectId, comment, parentId } = payload
    try {
      const { data } = await axiosinstance.post(
        `/project/${projectId}/comment`,
        {
          comment,
          projectId,
          parentId
        }
      )
      commit('updateReply', data.comments[0])
      console.log(data)
    } catch (err) {
      console.log(err)
    }
  },
  async upldateCommentLikes({ commit }, payload) {
    const { projectId, commentId, type } = payload
    try {
      await axiosinstance.post(`/project/${projectId}/comment-like`, {
        commentId,
        type
      })
      commit('updateCommentLike', payload)
    } catch (err) {
      console.log(err)
    }
  },

  async deleteComment({ commit }, payload) {
    const { projectId, commentId } = payload
    try {
      await axiosinstance.delete(`/project/${projectId}/comment/${commentId}`)
      commit('deleteComment', commentId)
    } catch (err) {
      console.log(err)
    }
  }
}
