export const getters = {
  projectData: (state, getters, rootState) => {
    return state.projectData
  },
  projectList: (state, getters, rootState) => {
    return state.projectList
  },
  ossCompilanceData: (state, getters, rootState) => {
    return state.ossCompilanceData
  },
  cveSecurityData: (state, getters, rootState) => {
    return state.cveSecurityData
  },
  tagsData: (state, getters, rootState) => {
    return state.tagsData
  },
  tagData: (state, getters, rootState) => {
    return state.tagData
  },
  fileTree: (state, getters, rootState) => {
    return state.fileTree
  },
  subFileTreeList: (state, getters, rootState) => {
    return state.subFileTreeList
  },
  productiveAccess: (state, getters, rootState) => {
    return state.productiveAccess
  },
  useRatingsData: (state, getters, rootState) => {
    return state.useRatingsData
  },
  depGraphData: (state) => {
    return state.depGraph
  },
  usersReviewList: (state, getters, rootState) => {
    return state.usersReviewList
  },
  bewertungen: (state) => state.bewertungen,
  bewertungenLength: (state) => state.bewertungenLength,
  bewertungenFilters: (state) => state.bewertungenFilters,
  scorecardDetails: (state, getters, rootState) => {
    return state.scorecardDetails
  }
}
