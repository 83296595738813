export const mutations = {
  insertMachbarkeitsnachweise(state, d) {
    state.machbarkeitsnachweise[d.id] = d
  },
  insertAllMachbarkeitsnachweise(state, { data, length }) {
    const mList = {}
    data.forEach((m) => {
      mList[m.id] = m
    })
    state.machbarkeitsnachweise = mList
    state.length = length
  },
  emptyMachbarkeitsnachweise(state) {
    state.machbarkeitsnachweise = {}
    state.length = 0
  },
  setLength(state, length) {
    state.length = length
  }
}
