<template>
  <div class="stars inline-flex relative">
    <svg
      v-for="nr in [1, 2, 3, 4, 5]"
      :key="nr"
      :id="`star-${nr}`"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      class="w-5 aspect-square flex-none"
    >
      <defs v-if="isPartialStar(nr)">
        <linearGradient :id="`gradient-${nr}`">
          <stop offset="0%" stop-color="#e64363" />
          <stop :offset="`${fillPercentage}%`" stop-color="#e64363" />
          <stop :offset="`${fillPercentage}%`" stop-color="#fff" />
          <stop offset="100%" stop-color="#fff" />
        </linearGradient>
      </defs>

      <path
        :id="`star-${nr}`"
        stroke="#e64363"
        :fill="getFill(nr)"
        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    rating: {
      type: Number,
      required: true
    }
  },
  computed: {
    fillPercentage() {
      const decimal = Number(`${this.rating}`.split('.')[1].slice(0, 1))

      return decimal * 10
    }
  },
  methods: {
    getFill(nr) {
      if (nr <= Math.floor(this.rating)) {
        return '#e64363'
      } else if (nr > Math.ceil(this.rating)) {
        return '#fff'
      } else {
        return `url(#gradient-${nr})`
      }
    },
    isPartialStar(nr) {
      return this.getFill(nr) === `url(#gradient-${nr})`
    }
  }
}
</script>
