<template>
  <Menu as="div" class="relative h-full text-white font-medium">
    <MenuButton
      class="flex items-center h-full whitespace-nowrap px-5"
      :class="{ 'bg-gray-darkest': $route.path.startsWith('/admin/') }"
    >
      Admin
    </MenuButton>

    <transition name="fade" class="origin-top">
      <MenuItems
        :class="[
          'absolute z-50 left-0 lg:-left-1/3 min-w-[10rem]',
          'bg-gray-light text-gray-dark',
          'origin-left lg:origin-top',
          'divide-y divide-gray-100 rounded-md shadow-lg'
        ]"
      >
        <MenuItem v-slot="{ active, close }" v-if="FEATURE_TOGGLES.editorial">
          <a
            @click="$router.push('/admin/editorial-dashboard').then(close)"
            :class="[
              $route.path === '/admin/editorial-dashboard'
                ? 'router-link-exact-active bg-gray-darkest text-white'
                : active
                ? 'bg-gray-darker text-gray-light'
                : 'bg-inherit text-inherit',
              'group flex w-full items-center justify-center px-2 py-4 text-sm'
            ]"
          >
            Redaktion
          </a>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { defineComponent } from 'vue'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

export default defineComponent({
  name: 'AdminNav',
  components: { Menu, MenuButton, MenuItems, MenuItem },
  data: () => ({ FEATURE_TOGGLES: window.FEATURE_TOGGLES })
})
</script>

<style scoped lang="postcss">
nav a.router-link-exact-active,
nav button.router-link-exact-active {
  @apply !text-red-primary;
}
</style>
