export const getters = {
  me: (state, getters, rootState) => {
    return state.me
  },
  userDetails: (state, getters, rootState) => {
    return state.userDetails
  },
  authUser: (state, getters, rootState) => {
    return state.authUser
  },
  list: (state) => {
    const list =
      Object.keys(state.list).length > 0
        ? state.list
        : { [state.authUser.id]: state.authUser }
    return list
  },
  isAnonymous: (state) => state.isAnonymous
}
