<template>
  <li class="flex h-6 flex-row lg:flex-col relative">
    <a
      class="inline-flex items-center px-2 gap-1 font-poppins text-[10px] sm:text-base"
      href="javascript:void(0)"
      @click="toggleNotification = !toggleNotification"
    >
      <img class="w-4 sm:w-6" src="../assets/icons/bookmark-svgrepo-com.svg" />
      Produktiv-Einsatz
    </a>
    <ul
      class="absolute top-8 z-10 w-max px-2 h-[90px] text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
      v-if="toggleNotification"
    >
      <li
        class="notification-chkbx w-full border-gray-200 rounded-t-lg dark:border-gray-600 pt-[10px]"
      >
        <div class="flex items-center pl-0">
          <label class="form-control" for="vue-checkbox-access-public">
            <input
              id="vue-checkbox-access-public"
              type="checkbox"
              :checked="
                productiveAccessData &&
                productiveAccessData[this.$route.params.id]
                  ?.productiveAccess === 'public'
              "
              @change="onSelect('public', $event)"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            />
            <span class="ml-[5px] font-poppins">Öffentlicher&nbsp;Eintrag</span>
          </label>
        </div>
      </li>
      <li
        class="notification-chkbx w-full border-gray-200 rounded-t-lg dark:border-gray-600 pt-[10px]"
      >
        <div class="flex items-center pl-0">
          <label class="form-control" for="vue-checkbox-access-private">
            <input
              id="vue-checkbox-access-private"
              type="checkbox"
              :checked="
                productiveAccessData &&
                productiveAccessData[this.$route.params.id]
                  ?.productiveAccess === 'private'
              "
              @change="onSelect('private', $event)"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            />
            <span class="ml-[5px] font-poppins">Privater&nbsp;Eintrag</span>
          </label>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ProductiveAccessSetting',
  components: {},
  data() {
    return {
      toggleNotification: false
    }
  },
  created() {
    // api call to fetch existing data
    const payload = {
      projectId: this.$route.params.id,
      key: 1
    }
    this.productiveAccessCall(payload)
  },
  methods: {
    ...mapActions({ productiveAccessCall: 'projectPage/productiveAccessCall' }),
    onSelect(selectedValue, event) {
      const isChecked = event.target.checked
      selectedValue = isChecked === false ? 'none' : selectedValue

      if (
        this.productiveAccessData &&
        this.productiveAccessData[this.$route.params.id]?.productiveAccess
      ) {
        // api call to update the value
        const payload = {
          projectId: this.$route.params.id,
          productiveAccess: selectedValue,
          key: 3
        }
        this.productiveAccessCall(payload)
      } else {
        // api call to add new data
        const payload = {
          projectId: this.$route.params.id,
          productiveAccess: selectedValue,
          key: 2
        }
        this.productiveAccessCall(payload)
      }
    }
  },
  computed: {
    ...mapGetters({ productiveAccessData: 'projectPage/productiveAccess' })
  }
}
</script>

<style>
.form-control {
  font-size: 18px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  margin: 3px 3px;
  margin-left: 10px;
}

.notification-chkbx input[type='checkbox'] {
  cursor: pointer;
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: white;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #e0004b;
  width: 25px;
  height: 25px;
  border: 2px solid black;
  border-radius: 0;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

.notification-chkbx input[type='checkbox']::before {
  content: '';
  width: 20px;
  height: 20px;
  /*clip-path: polygon(14% 41%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 40% 62%);*/
  clip-path: polygon(
    30% 70%,
    90% 20%,
    92% 32%,
    59% 60%,
    28% 85%,
    8% 49%,
    16% 39%
  );
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #e0004b;
}

.notification-chkbx input[type='checkbox']:checked::before {
  transform: scale(1);
}

.notification-chkbx input[type='checkbox']:focus {
  outline: none;
  outline-offset: max(2px, 0.15em);
}

.notification-chkbx input[type='checkbox']:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}
</style>
