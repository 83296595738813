<template>
  <div class="editorial-container">
    <header class="!min-w-min">
      <div>
        <h1>Redaktion</h1>
        <h4 class="text-center sm:text-center font-poppins text-[20px]"></h4>
      </div>
    </header>

    <section class="project-review-section my-10">
      <LayoutContainer class="flex flex-col justify-center gap-y-5">
        <div class="flex flex-wrap justify-center items-center gap-2">
          <div class="search">
            <input
              :tabindex="0"
              v-model="searchText"
              @update:modelValue="updateSearch"
              type="search"
              name="search"
              id="search"
              placeholder="Suche..."
            />
          </div>
          <div class="project filter">
            <Combobox v-model="project" @update:modelValue="update" multiple>
              <ComboboxInput
                @change="projectQuery = $event.target.value"
                placeholder="Komponente filtern..."
              />
              <ComboboxOptions as="ul">
                <ComboboxOption
                  as="li"
                  v-for="project in filteredProject"
                  v-slot="{ selected, active }"
                  :key="project.id"
                  :value="project.id"
                >
                  <abbr
                    :class="{
                      'text-red-primary': selected,
                      'bg-gray-light': active
                    }"
                    :title="project.title"
                    >{{ project.title }}</abbr
                  >
                </ComboboxOption>
              </ComboboxOptions>
            </Combobox>
          </div>
          <div class="user filter">
            <Combobox v-model="user" @update:modelValue="update" multiple>
              <ComboboxInput
                @change="userQuery = $event.target.value"
                placeholder="Mitarbeiter filtern..."
              />
              <ComboboxOptions as="ul">
                <ComboboxOption
                  as="li"
                  v-for="user in filteredUser"
                  v-slot="{ selected, active }"
                  :key="user.id"
                  :value="user.id"
                >
                  <abbr
                    :class="{
                      'text-red-primary': selected,
                      'bg-gray-light': active
                    }"
                    :title="user.email"
                    >{{ user.username }}</abbr
                  >
                </ComboboxOption>
              </ComboboxOptions>
            </Combobox>
          </div>
          <div class="status filter">
            <Listbox v-model="status" @update:modelValue="update" multiple>
              <ListboxButton>Zustände&nbsp;filtern...</ListboxButton>
              <ListboxOptions as="ul">
                <ListboxOption
                  as="li"
                  v-for="s in ['accepted', 'rejected', 'pending']"
                  v-slot="{ selected, active }"
                  :key="s"
                  :value="s"
                >
                  <span
                    class="capitalize"
                    :class="{
                      'text-red-primary': selected,
                      'bg-gray-light': active
                    }"
                  >
                    {{ s }}
                  </span>
                </ListboxOption>
              </ListboxOptions>
            </Listbox>
          </div>
        </div>

        <ul class="flex flex-wrap gap-2 items-center justify-start">
          <li
            @click="update(status.splice(i, 1))"
            class="px-2 py-1 bg-gray-lighter border border-gray-light rounded-full cursor-default"
            v-for="(s, i) in status"
            :key="s"
          >
            {{ s }} <span class="close">x</span>
          </li>
          <li
            @click="update(project.splice(i, 1))"
            class="px-2 py-1 bg-gray-lighter border border-gray-light rounded-full cursor-default"
            v-for="(p, i) in project"
            :key="p"
          >
            {{ bewertungenFilters.projects.find((pr) => pr.id === p).title }}
            <span class="close">x</span>
          </li>
          <li
            @click="update(user.splice(i, 1))"
            class="px-2 py-1 bg-gray-lighter border border-gray-light rounded-full cursor-default"
            v-for="(u, i) in user"
            :key="u"
          >
            <abbr
              :title="bewertungenFilters.users.find((ur) => ur.id === u).email"
              >{{
                bewertungenFilters.users.find((ur) => ur.id === u).username
              }}</abbr
            >
            <span class="close">x</span>
          </li>
        </ul>

        <div class="w-full overflow-x-auto scroll-smooth">
          <table class="table-fixed text-xs md:text-md">
            <thead>
              <tr>
                <th>
                  <span class="table-head">
                    <p>OSS-Produkt</p>
                    <div class="sort">
                      <span
                        class="up"
                        @click="setSort('projectName', 'asc')"
                        :class="{
                          active: orderBy === 'projectName' && order === 'asc'
                        }"
                      >
                        <img
                          src="../assets/icons/down-arrow-svgrepo-com.svg"
                          alt="Sort project ascending"
                        />
                      </span>
                      <span
                        class="down"
                        @click="setSort('projectName', 'desc')"
                        :class="{
                          active: orderBy === 'projectName' && order === 'desc'
                        }"
                      >
                        <img
                          src="../assets/icons/down-arrow-svgrepo-com.svg"
                          alt="Sort project descending"
                        />
                      </span>
                    </div>
                  </span>
                </th>
                <th>
                  <span class="table-head">
                    <p>Organisation</p>
                    <div class="sort">
                      <span
                        class="up"
                        @click="setSort('organisation', 'asc')"
                        :class="{
                          active: orderBy === 'organisation' && order === 'asc'
                        }"
                      >
                        <img
                          src="../assets/icons/down-arrow-svgrepo-com.svg"
                          alt="Sort organisation ascending"
                        />
                      </span>
                      <span
                        class="down"
                        @click="setSort('organisation', 'desc')"
                        :class="{
                          active: orderBy === 'organisation' && order === 'desc'
                        }"
                      >
                        <img
                          src="../assets/icons/down-arrow-svgrepo-com.svg"
                          alt="Sort organisation descending"
                        />
                      </span>
                    </div>
                  </span>
                </th>
                <th>
                  <span class="table-head">
                    <p>Status</p>
                    <div class="sort">
                      <span
                        class="up"
                        @click="setSort('acceptanceStatus', 'asc')"
                        :class="{
                          active:
                            orderBy === 'acceptanceStatus' && order === 'asc'
                        }"
                      >
                        <img
                          src="../assets/icons/down-arrow-svgrepo-com.svg"
                          alt="Sort acceptanceStatus ascending"
                        />
                      </span>
                      <span
                        class="down"
                        @click="setSort('acceptanceStatus', 'desc')"
                        :class="{
                          active:
                            orderBy === 'acceptanceStatus' && order === 'desc'
                        }"
                      >
                        <img
                          src="../assets/icons/down-arrow-svgrepo-com.svg"
                          alt="Sort acceptanceStatus descending"
                        />
                      </span>
                    </div>
                  </span>
                </th>
                <th>
                  <span class="table-head">
                    <p>Last updated at</p>
                    <div class="sort">
                      <span
                        class="up"
                        @click="setSort('updatedAt', 'asc')"
                        :class="{
                          active: orderBy === 'updatedAt' && order === 'asc'
                        }"
                      >
                        <img
                          src="../assets/icons/down-arrow-svgrepo-com.svg"
                          alt="Sort updatedAt ascending"
                        />
                      </span>
                      <span
                        class="down"
                        @click="setSort('updatedAt', 'desc')"
                        :class="{
                          active: orderBy === 'updatedAt' && order === 'desc'
                        }"
                      >
                        <img
                          src="../assets/icons/down-arrow-svgrepo-com.svg"
                          alt="Sort updatedAt descending"
                        />
                      </span>
                    </div>
                  </span>
                </th>
                <th>Zum&nbsp;Steckbrief</th>
              </tr>
            </thead>
            <tbody>
              <transition-group name="fade">
                <tr v-for="d in bewertungen" :key="d.id">
                  <td>
                    <router-link
                      :tabindex="-1"
                      :to="`/project/${d.projectId}`"
                      class="text-blue-900 hover:text-opacity-60"
                    >
                      {{ d.projectName || 'Projekt #' + d.projectId }}
                    </router-link>
                  </td>
                  <td :tabindex="-1">{{ d.organisation }}</td>
                  <td>
                    <Popover
                      :set="(tooltip[d.id] = false)"
                      :class="{
                        'pointer-events-none': d.acceptanceStatus !== 'rejected'
                      }"
                    >
                      <PopoverButton
                        :tabindex="-1"
                        v-on:mouseenter="tooltip[d.id] = true"
                        v-on:mouseleave="tooltip[d.id] = false"
                      >
                        {{ d.acceptanceStatus }}
                      </PopoverButton>
                      <transition name="fade">
                        <PopoverPanel
                          v-if="
                            d.acceptanceStatus === 'rejected' && tooltip[d.id]
                          "
                          static
                          class="w-max max-w-xs absolute z-10 -translate-x-1/4"
                        >
                          <p
                            class="bg-white text-black px-2 py-1 mt-1 rounded shadow ring-1 ring-black ring-opacity-5"
                          >
                            <strong>Begründung:</strong> {{ d.reason }}
                          </p>
                        </PopoverPanel>
                      </transition>
                    </Popover>
                  </td>
                  <td :tabindex="-1" class="justify-content: unset">
                    <p class="grid-item pt-2">
                      <timeago :datetime="d.updatedAt" />
                    </p>
                  </td>
                  <td class="mx-auto">
                    <OverviewButton :info="d" @update="statusUpdate" />
                  </td>
                </tr>
              </transition-group>
            </tbody>
          </table>
        </div>
        <DynamicPagination
          :page="page"
          :length="length"
          :limit="limit"
          :key="`pagination-${length}-page-${page}`"
          @click="showPage"
        />
      </LayoutContainer>
    </section>
  </div>
</template>

<script>
import { ref, defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption
} from '@headlessui/vue'
import OverviewButton from '@/components/EditorialView/OverviewButton.vue'
import LayoutContainer from '@/components/LayoutContainer.vue'
import debounce from 'lodash.debounce'
import DynamicPagination from '@/components/Pagination/DynamicPagination.vue'

export default defineComponent({
  name: 'EditorialView',
  components: {
    OverviewButton,
    LayoutContainer,
    Popover,
    PopoverButton,
    PopoverPanel,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    DynamicPagination
  },
  data() {
    return {
      order: ref(null),
      orderBy: ref(null),
      tooltip: ref({}),
      page: ref(0),
      limit: 10,
      visibleData: [],
      searchText: '',
      status: [],
      project: [],
      projectQuery: ref(''),
      filteredProject: ref([]),
      user: [],
      userQuery: ref(''),
      filteredUser: ref([])
    }
  },
  created() {
    this.emptyBewertungen()
  },
  async mounted() {
    await Promise.all([this.getFilters(), this.getBewertungen(this)])

    this.filteredUser = this.bewertungenFilters.users
    this.filteredProject = this.bewertungenFilters.projects
  },
  computed: {
    ...mapGetters({
      bewertungen: 'projectPage/bewertungen',
      length: 'projectPage/bewertungenLength',
      bewertungenFilters: 'projectPage/bewertungenFilters'
    })
  },
  methods: {
    ...mapMutations({
      emptyBewertungen: 'projectPage/emptyBewertungen',
      upsertBewertungen: 'projectPage/upsertBewertungen'
    }),
    ...mapActions({
      getBewertungen: 'projectPage/getBewertungen',
      getFilters: 'projectPage/getBewertungenFilters'
    }),
    items(list) {
      this.visibleData = list
    },
    updateSearch: debounce(function() {
      this.update()
    }, 500),
    update(ev) {
      this.page = ev?.page ?? 0
      this.userQuery = ''
      this.projectQuery = ''
      this.emptyBewertungen()
      this.getBewertungen(this)
    },
    setSort(orderBy, order) {
      if (this.orderBy === orderBy && this.order === order) {
        this.orderBy = null
      } else {
        this.orderBy = orderBy
        this.order = order
      }
      this.update()
    },
    showPage(page) {
      this.update({ page })
    },
    statusUpdate(bewertungen) {
      this.upsertBewertungen(bewertungen)
    }
  },
  watch: {
    searchText() {
      this.updateSearch()
    },
    userQuery(to) {
      this.filteredUser =
        to.trim() === ''
          ? this.bewertungenFilters.users
          : this.bewertungenFilters.users.filter((user) => {
            return (
              user.username.toLowerCase().includes(to.toLowerCase()) ||
                user.email.toLowerCase().includes(to.toLowerCase())
            )
          })
    },
    projectQuery(to) {
      this.filteredProject =
        to.trim() === ''
          ? this.bewertungenFilters.projects
          : this.bewertungenFilters.projects.filter((project) => {
            return project.title.toLowerCase().includes(to.toLowerCase())
          })
    }
  }
})
</script>

<style scoped lang="postcss">
span.close {
  @apply text-icon p-1 cursor-pointer;
}

.search {
  @apply w-full md:flex-1;
}

.search,
.filter > button,
.filter > input {
  @apply bg-gray-lighter border-2 border-gray-light;
  @apply placeholder:text-gray-400;
  @apply px-2 md:px-4 py-1 md:py-2 text-xs md:text-sm;
  @apply rounded-full;
  @apply w-full capitalize line-clamp-1;
}

.filter {
  @apply relative;
  @apply w-full md:w-36;
}

.filter > button {
  @apply text-gray-400;
}

.project.filter,
.user.filter {
  @apply md:w-48;
}

.filter ul {
  @apply absolute bg-gray-lighter z-10;
  @apply w-full mt-2 rounded border-2 border-gray-light;
  @apply flex flex-col divide-y-2 divide-gray-light;
  @apply max-h-52 overflow-y-auto;
}

.filter ul > li {
  @apply cursor-pointer;
}

.filter ul > li > abbr,
.filter ul > li > span {
  @apply px-3 py-2 h-8;
  @apply overflow-x-hidden line-clamp-1;
}

.search > input {
  @apply bg-transparent;
  @apply focus:outline-none;
  @apply w-full;
}

input[type='search']::-webkit-search-cancel-button {
  @apply appearance-none cursor-pointer p-1;
  @apply h-2 w-2;
  @apply bg-contain bg-no-repeat;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKICAgICAgICAgICAgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsgY29sb3I6I2Y5MmQ2MCIKICAgICAgICAgICAgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIGZpbGw9ImN1cnJlbnRDb2xvciIKICAgICAgICAgICAgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CiAgICAgICAgICA8Zz4KICAgICAgICAgICAgPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQogICAgICAgICAgICAgIGMtMi4zMDEsMi4zLTIuMzAxLDYuMSwwLDguNWw0My4xLDQzLjFsLTQyLjMsNDIuNWMtMi4zLDIuMy0yLjMsNi4xLDAsOC41bDguNSw4LjVjMi4zLDIuMyw2LjEsMi4zLDguNSwwbDQyLjM5OS00Mi40bDQyLjQsNDIuNAogICAgICAgICAgICAgIGMyLjMsMi4zLDYuMSwyLjMsOC41LDBsOC41LTguNWMyLjMtMi4zLDIuMy02LjEsMC04LjVsLTQyLjUtNDIuNGw0Mi40LTQyLjM5OUMxMjMuNjI1LDE3LjEyNSwxMjMuNjI1LDEzLjMyNSwxMjEuMzI1LDEwLjkyNXoiLz4KICAgICAgICAgIDwvZz4KICAgICAgICAgIDwvc3ZnPg==);
}

header {
  @apply flex flex-wrap items-center py-8 lg:py-16 bg-banner bg-gray-lighter bg-no-repeat bg-cover;
  @apply min-h-[10rem] md:min-h-[15rem] 2xl:min-h-[20rem];
}

header > div {
  @apply mx-auto max-w-screen-xl;
}

header h1 {
  @apply text-center leading-[65px] font-poppinsbold text-gray-darker text-3xl md:text-4xl;
}

button {
  @apply rounded border;
  @apply px-4 py-2;
  @apply text-white;
}

tr > th:nth-child(1) {
  @apply w-3/6;
}
tr > th:nth-child(2) {
  @apply w-1/6;
}
tr > th:nth-child(3) {
  @apply w-1/6;
}
tr > th:nth-child(4) {
  @apply w-1/6;
}
tr > td:nth-child(4) {
  @apply flex justify-center;
}

.table-head {
  @apply h-full w-full flex flex-row items-center;
}

th p {
  @apply flex-1;
}

.sort > span > img {
  @apply w-3 cursor-pointer opacity-40;
}

.sort > span.active > img {
  @apply opacity-100;
}

.sort .up > img {
  @apply rotate-180;
}
</style>
