import orderBy from 'lodash.orderby'
export const mutations = {
  projectList(state, projectList) {
    state.projectList = projectList
  },
  sortJobs(state, payload) {
    if (payload[0]?.ascending === true) {
      state.projectList =
        payload[0]?.sortBy === 'updatedAt'
          ? orderBy(
            state.projectList,
            [(item) => new Date(item[payload[0]?.sortBy])],
            ['asc']
          )
          : orderBy(
            state.projectList,
            [(item) => item[payload[0]?.sortBy]?.toLowerCase()],
            ['asc']
          )
    } else {
      state.projectList =
        payload[0].sortBy === 'updatedAt'
          ? orderBy(
            state.projectList,
            [(item) => new Date(item[payload[0]?.sortBy])],
            ['desc']
          )
          : orderBy(
            state.projectList,
            [(item) => item[payload[0]?.sortBy]?.toLowerCase()],
            ['desc']
          )
    }
  },
  ossFiltering(state, payload) {
    if (payload.length > 0) {
      state.projectList = state.projectList.filter((obj1) =>
        payload.some((obj2) => obj2.value === obj1.legal)
      )
    }
  },
  projectInfo(state, projectData) {
    if (!state.projectData) {
      state.projectData = {}
    }
    state.projectData[projectData?.projectDetails?.id] = projectData
  },
  fileTree(state, fileTree) {
    if (!state.fileTree) {
      state.fileTree = {}
    }
    state.fileTree[fileTree.id] = fileTree.tree
  },
  subFileTreeList(state, subFileTreeList) {
    if (!state.subFileTreeList) {
      state.subFileTreeList = {}
    }
    if (subFileTreeList.path !== '') {
      subFileTreeList.tree.unshift({
        id: 0,
        mode: '',
        name: '...',
        path: subFileTreeList.path,
        type: ''
      })
    }
    state.subFileTreeList[subFileTreeList.id] = subFileTreeList.tree
  },
  ossCompilance(state, ossCompilanceData) {
    if (!state.ossCompilanceData) {
      state.ossCompilanceData = {}
    }
    state.ossCompilanceData[ossCompilanceData.id] = ossCompilanceData.result
  },
  cveSecurity(state, cveSecurityData) {
    if (!state.cveSecurityData) {
      state.cveSecurityData = {}
    }
    state.cveSecurityData[cveSecurityData.id] = cveSecurityData.result
  },
  tagsList(state, tagsData) {
    if (!state.tagsData) {
      state.tagsData = {}
    }
    state.tagsData[tagsData.id] = tagsData.tagsList
  },
  tagData(state, tagData) {
    if (!state.tagData) {
      state.tagData = {}
    }
    state.tagData[tagData?.data?.id?.projectId] = tagData?.data
  },
  productiveAccess(state, productiveAccess) {
    if (!state.productiveAccess) {
      state.productiveAccess = {}
    }
    state.productiveAccess[productiveAccess.projectId] = productiveAccess
  },
  useRatingsData(state, useRatingsData) {
    if (!state.useRatingsData) {
      state.useRatingsData = {}
    }
    state.useRatingsData[useRatingsData.projectId] = useRatingsData
  },
  depGraphData(state, { data, id, error }) {
    if (error) {
      state.depGraph[id] = {}
    }
    state.depGraph[id] = data
  },
  usersReviewList(state, usersReviewList) {
    if (!state.usersReviewList) {
      state.usersReviewList = {}
    }
    state.usersReviewList[usersReviewList.projectId] = usersReviewList.data
  },
  insertBewertungen(state, bewertungen) {
    state.bewertungen = bewertungen
  },
  putBewertungenLength(state, length) {
    state.bewertungenLength = length
  },
  upsertBewertungen(state, bewertungen) {
    const sB = state.bewertungen.find((b) => b.id === bewertungen.id)
    const index = state.bewertungen.indexOf(sB)
    if (index > -1) {
      state.bewertungen[index] = bewertungen
    } else {
      state.bewertungen.push(bewertungen)
    }
  },
  emptyBewertungen(state) {
    state.bewertungen = []
  },
  putBewertungenFilters(state, bewertungenFilters) {
    state.bewertungenFilters = bewertungenFilters
  },
  scorecardDetails(state, scorecardDetails) {
    if (!state.scorecardDetails) {
      state.scorecardDetails = {}
    }
    state.scorecardDetails[scorecardDetails.projectId] = scorecardDetails.data
  }
}
