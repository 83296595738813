<template>
  <footer class="bg-gray-darkest py-8 lg:pb-4 mt-10">
    <div class="max-w-screen-lg mx-auto px-6 flex flex-col">
      <div
        class="flex flex-col space-y-8 lg:space-y-0 lg:flex-row items-center justify-between"
      >
        <router-link
          :to="{ name: 'DashboardView' }"
          class="font-poppins whitespace-nowrap text-white font-black lg:text-26"
        >
          <img class="h-10" src="../assets/images/Logo_OpenCoDE_NEG.svg" />
        </router-link>
      </div>

      <ul
        class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6 mt-12 items-center justify-center lg:justify-start"
      >
        <li class="font-sans text-white text-14">
          {{ copyRightInfo }}
        </li>
        <li v-for="({ text, href }, i) in links" :key="i">
          <a
            :href="href"
            class="font-sans text-white text-14 hover:underline"
            >{{ text }}</a
          >
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'OC-Footer',
  props: [],
  components: {},
  data() {
    return {
      links: [
        {
          text: 'Impressum',
          href: 'https://wikijs.opencode.de/de/Impressum'
        },
        {
          text: 'Datenschutz',
          href: 'https://wikijs.opencode.de/de/Datenschutz'
        },
        {
          text: 'Nutzungsbedingungen',
          href: 'https://wikijs.opencode.de/de/Hilfestellungen_und_Richtlinien/Nutzungsbedingungen'
        },
        {
          text: 'Kontakt',
          href: 'https://wikijs.opencode.de/de/Kontakt'
        }
      ],
      copyRightInfo: '© 2023 OpenCoDE'
    }
  },
  created() {},
  methods: {},
  computed: {}
}
</script>

<style>
.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
}
</style>
