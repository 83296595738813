import instance from '@/services/axiosInstance'
import axios from 'axios'
const API = window.API

export async function login(redirectTo = null) {
  try {
    const { data } = await instance.get('me')
    if (data.code === 302) {
      localStorage.setItem('oidcCallbackUrl', data.callbackUrl)
      const uri = new URL(data.redirectUrl)
      const httpURI = uri.href.slice(0, uri.href.indexOf('/openid-connect'))
      const clientUri = new URL(window.location.origin)

      const logoutURI = new URL(httpURI + '/openid-connect/logout')
      logoutURI.searchParams.set('post_logout_redirect_uri', clientUri.href)

      localStorage.setItem('postLogoutUri', logoutURI.href)

      if (typeof redirectTo === 'string' && redirectTo.trim().length > 0) {
        localStorage.setItem('postLoginRedirect', redirectTo)
      } else {
        localStorage.setItem('postLoginRedirect', window.location.toString())
      }
      window.location.replace(uri)
    }
  } catch (ex) {
    if (axios.isAxiosError(ex)) {
      console.error(ex.toJSON())
    }
  }
}

export async function logout() {
  try {
    await instance.get(`${API}/logout`)
    const redirectTo = localStorage.getItem('postLogoutUri')
    if (redirectTo) {
      localStorage.removeItem('postLogoutUri')
      window.location.replace(redirectTo)
    }
  } catch (err) {
    console.error(axios.isAxiosError(err) ? err.toJSON() : err)
  }
}
