<template>
  <nav
    aria-label="Page navigation example"
    class="inline-flex justify-center"
    style="width: 100%"
  >
    <button
      @click="previousPage"
      class="min-w-[100px] leading-[22px] mr-[5px] px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
      :disabled="isFirstPage"
      :class="[isFirstPage ? 'bg-gray-light' : 'bg-white']"
    >
      <span class="text-red mr-2">&#9664;</span>Zurück
    </button>
    <div class="w-96 text-center">
      <ul class="inline-flex -space-x-px leading-[40px]">
        <li
          v-for="element in elements"
          :key="element.page"
          :current="currentPage"
          class="w-10 text-center"
        >
          <template v-if="element.type == 'page'">
            <a
              href="javascript:void(0)"
              class="leading-[21px] px-3 py-2 leading-tight border"
              @click="selectedPage(element.page)"
              :class="[
                element.page === currentPage
                  ? 'bg-red text-white border-red'
                  : 'bg-white hover:bg-gray-light text-gray-500'
              ]"
              >{{ element.page }}</a
            >
          </template>
          <template v-else>
            <span>&hellip;</span>
          </template>
        </li>
      </ul>
    </div>

    <button
      @click="nextPage"
      class="min-w-[100px] leading-[22px] ml-[5px] px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700"
      :disabled="isLastPage"
      :class="[isFirstPage ? 'bg-gray-light' : 'bg-white']"
    >
      Weiter <span class="text-red ml-1">&#9654;</span>
    </button>
  </nav>
</template>
<script>
export default {
  name: 'PaginationComponent',
  props: {
    pageData: {
      type: Array,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    elements: [],
    currentPage: 1,
    totalPages: 1,
    pageCount: 5
  }),
  methods: {
    goToFirstPage() {
      this.currentPage = 1
      this.paginate()
    },
    goToLastPage() {
      this.currentPage = this.totalPages
      this.paginate()
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--
        this.paginate()
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
        this.paginate()
      }
    },
    selectedPage(page) {
      this.currentPage = page
      this.paginate()
    },
    dataToReturn(page) {
      const from = page * this.perPage - this.perPage
      const to = page * this.perPage
      const items = JSON.parse(JSON.stringify(this.pageData))
      return items.slice(from, to)
    },
    emitPaginatedData(data) {
      const items = JSON.parse(JSON.stringify(data))
      const itemsLength = items.length
      this.totalPages =
        itemsLength > this.perPage ? Math.ceil(itemsLength / this.perPage) : 1
      const slicedData = this.dataToReturn(1)
      this.paginate()
      this.$emit('paginatedItems', slicedData)
    },
    add(s, f) {
      for (let i = s; i <= f; i++) {
        this.elements.push({
          type: 'page',
          page: i
        })
      }
    },
    addBreak() {
      this.elements.push({
        type: 'ellipse-break'
      })
    },
    first() {
      // Add first page
      this.elements.push({
        type: 'page',
        page: 1
      })
    },
    last() {
      // Add last page
      this.elements.push({
        type: 'page',
        page: this.totalPages
      })
    },
    paginate() {
      this.elements = []
      const t = this.totalPages > 4 ? Math.floor(this.pageCount / 2) : 4
      if (this.totalPages <= t) {
        // Case without any breaks
        this.add(1, this.totalPages)
      } else if (this.currentPage >= this.totalPages - t) {
        // Case with breaks at beginning
        this.first()
        this.addBreak()
        const startIndex = this.currentPage - t === 0 ? 3 : this.currentPage - t
        this.add(startIndex, this.totalPages)
      } else if (this.currentPage <= t + 1) {
        // Case with breaks at end
        const length =
          this.currentPage + (t + 1) >= this.totalPages
            ? this.currentPage + (t + 1) - 1
            : this.currentPage + (t + 1)
        this.add(1, length)
        this.addBreak()
        this.last()
      } else {
        // Case with breaks at beginning and end
        this.first()
        this.addBreak()
        this.add(this.currentPage - (t - 1), this.currentPage + t)
        this.addBreak()
        this.last()
      }
    }
  },
  computed: {
    isFirstPage() {
      return this.currentPage === 1
    },
    isLastPage() {
      return this.currentPage === this.totalPages
    }
  },
  created() {
    this.emitPaginatedData(this.pageData)
  },
  watch: {
    currentPage(newValue, oldValue) {
      // Do something when the currentPage is updated
      const slicedData = this.dataToReturn(newValue)
      this.$emit('paginatedItems', slicedData)
    },
    pageData(newValue, oldValue) {
      this.emitPaginatedData(newValue)
    }
  }
}
</script>
