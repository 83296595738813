export const filters = [
  {
    name: 'Entwicklungsstand',
    icon: 'brickwall-stone-svgrepo-com',
    options: [
      { name: 'in Konzeption', value: '.developmentstatus-concept' },
      { name: 'in Entwicklung', value: '.developmentstatus-development' },
      { name: 'Beta-Version', value: '.developmentstatus-beta' },
      { name: 'stabile Version', value: '.developmentstatus-stable' },
      { name: 'veraltet', value: '.developmentstatus-obsolete' }
    ]
  },
  {
    name: 'Plattform',
    icon: 'left-right-arrow-2-svgrepo-com',
    options: [
      { name: 'Windows', value: '.platforms-windows' },
      { name: 'Linux', value: '.platforms-linux' },
      { name: 'Web', value: '.platforms-web' },
      { name: 'Mac', value: '.platforms-mac' },
      { name: 'iOS', value: '.platforms-ios' },
      { name: 'Android', value: '.platforms-android' }
    ]
  },
  {
    openByDefault: true,
    name: 'Kategorie',
    icon: 'folder-svgrepo-com',
    options: [
      {
        name: 'Abrechnung und Rechnungserstellung',
        value: '.categories-billing-and-invoicing'
      },
      { name: 'Buchhaltung', value: '.categories-accounting' }, // Accounting
      {
        name: 'Agiles Projektmanagement',
        value: '.categories-agile-project-management'
      },
      {
        name: 'Anwendungsentwicklung',
        value: '.categories-application-development'
      },
      {
        name: 'Asset-Management',
        value: '.categories-digital-asset-management'
      },
      { name: 'Aufgabenmanagement', value: '.categories-task-management' },
      { name: 'Sicherung', value: '.categories-backup' }, // Backup
      { name: 'Beschaffung', value: '.categories-procurement' },
      {
        name: 'Besuchermanagement',
        value: '.categories-visitor-management'
      },
      {
        name: 'Bewerbermanagement',
        value: '.categories-applicant-tracking'
      },
      { name: 'Blog', value: '.categories-blog' },
      { name: 'Budgetierung', value: '.categories-budgeting' },
      { name: 'Bürokommunikation', value: '.categories-office' },
      {
        name: 'Business Intelligence',
        value: '.categories-business-intelligence'
      },
      { name: 'CAD', value: '.categories-cad' },
      {
        name: 'Call Center Management',
        value: '.categories-call-center-management'
      },
      { name: 'Cloud Management', value: '.categories-cloud-management' },
      {
        name: 'Compliance Management',
        value: '.categories-compliance-management'
      },
      {
        name: 'Content Management',
        value: '.categories-content-management'
      },
      {
        name: 'Kundenbeziehungs Management', // Customer Relationship Management (CRM)
        value: '.categories-crm'
      },
      { name: 'Datenanalyse', value: '.categories-data-analytics' },
      { name: 'Datenakquise', value: '.categories-data-collection' },
      {
        name: 'Datenvisualisierung',
        value: '.categories-data-visualization'
      },
      {
        name: 'Digitale Gesellschaft',
        value: '.categories-digital-citizenship'
      },
      { name: 'Digitale Signatur', value: '.categories-e-signature' },
      {
        name: 'Dokumenten-Management',
        value: '.categories-document-management'
      },
      { name: 'E-Commerce', value: '.categories-e-commerce' },
      { name: 'E-Mail-Management', value: '.categories-email-management' },
      { name: 'E-Mail-Marketing', value: '.categories-email-marketing' },
      { name: 'ERP', value: '.categories-erp' },
      {
        name: 'Veranstaltungs Management',
        value: '.categories-event-management'
      }, // Event-Management
      {
        name: 'Facility-Management',
        value: '.categories-facility-management'
      },
      {
        name: 'Feedback Management',
        value: '.categories-feedback-and-reviews-management'
      },
      { name: 'Fernwartung', value: '.categories-remote-support' },
      {
        name: 'Finanz-Reporting',
        value: '.categories-financial-reporting'
      },
      { name: 'Finanzmittelbeschaffung', value: '.categories-fundraising' },
      { name: 'Flotten-Management', value: '.categories-fleet-management' },
      {
        name: 'Fördermittelverwaltung',
        value: '.categories-grant-management'
      },
      { name: 'Gamification', value: '.categories-gamification' },
      { name: 'Gehaltsabrechnung', value: '.categories-payroll' },
      {
        name: 'Geo-Informationssysteme',
        value: '.categories-geographic-information-systems'
      },
      { name: 'Grafikdesign', value: '.categories-graphic-design' },
      {
        name: 'Grundstücksmanagement',
        value: '.categories-property-management'
      },
      { name: 'Help-Desk', value: '.categories-help-desk' },
      { name: 'Humanressourcen', value: '.categories-hr' }, // Human Ressources (HR)
      { name: 'IDE', value: '.categories-ide' },
      {
        name: 'Identitäts Management', // Identity Management
        value: '.categories-identity-management'
      },
      {
        name: 'Immobilienmanagement',
        value: '.categories-real-estate-management'
      },
      {
        name: 'Sofortige Nachrichtenübermittlung',
        value: '.categories-instant-messaging'
      }, // Instant Messaging
      {
        name: 'IT-Asset Management',
        value: '.categories-it-asset-management'
      },
      { name: 'IT-Entwicklung', value: '.categories-it-development' },
      { name: 'IT-Management', value: '.categories-it-management' },
      { name: 'IT-Sicherheit', value: '.categories-it-security' },
      {
        name: 'IT-Service Management',
        value: '.categories-it-service-management'
      },
      { name: 'Kollaboration', value: '.categories-collaboration' },
      { name: 'Kommunikation', value: '.categories-communications' },
      {
        name: 'Kontakt Management',
        value: '.categories-contact-management'
      },
      {
        name: 'Kundenservice und -support',
        value: '.categories-customer-service-and-support'
      },
      {
        name: 'Lagerverwaltung',
        value: '.categories-warehouse-management'
      },
      { name: 'Marketing', value: '.categories-marketing' },
      { name: 'Mind-Mapping-Tools', value: '.categories-mind-mapping' },
      { name: 'Mobiles Marketing', value: '.categories-mobile-marketing' },
      { name: 'Mobiles Bezahlen', value: '.categories-mobile-payment' },
      {
        name: 'Netzwerk-Management',
        value: '.categories-network-management'
      },
      { name: 'Online-Buchung', value: '.categories-online-booking' },
      {
        name: 'Online-Bezahlsystem',
        value: '.categories-online-community'
      },
      {
        name: 'Personalmanagement',
        value: '.categories-employee-management'
      },
      {
        name: 'Prädiktive Analyse',
        value: '.categories-predictive-analysis'
      },
      {
        name: 'Productivitätssuite',
        value: '.categories-productivity-suite'
      },
      {
        name: 'Projekt-Zusammenarbeit', // Project-Collaboration
        value: '.categories-project-collaboration'
      },
      {
        name: 'Projektmanagement',
        value: '.categories-project-management'
      },
      {
        name: 'Projektmanagement (Unternehmen)',
        value: '.categories-enterprise-project-management'
      },
      {
        name: 'Prozessmanagement',
        value: '.categories-business-process-management'
      },
      {
        name: 'Ressourcenanagement',
        value: '.categories-resource-management'
      },
      {
        name: 'Schulungsmanagement-Systeme',
        value: '.categories-learning-management-system'
      },
      {
        name: 'Optimierung der Suchmaschine', // Search Engine Optimization (SEO)
        value: '.categories-seo'
      },
      { name: 'Service Desk', value: '.categories-service-desk' },
      {
        name: 'Verwaltung sozialer Medien', // Social Media Management
        value: '.categories-social-media-management'
      },
      {
        name: 'Soziales Netzwerk (Unternehmen)',
        value: '.categories-enterprise-social-networking'
      },
      { name: 'Spenden-Management', value: '.categories-donor-management' },
      { name: 'Steuerverwaltung', value: '.categories-taxes-management' },
      { name: 'Talent Management', value: '.categories-talent-management' },
      {
        name: 'Terminvereinbarung',
        value: '.categories-appointment-scheduling'
      },
      { name: 'Test-Management', value: '.categories-test-management' },
      { name: 'Übersetzungen', value: '.categories-translation' },
      { name: 'Umfragesysteme', value: '.categories-survey' },
      {
        name: 'Vertriebsmanagement',
        value: '.categories-sales-management'
      },
      { name: 'Videobearbeitung', value: '.categories-video-editing' },
      {
        name: 'Videokonferenzsysteme',
        value: '.categories-video-conferencing'
      },
      { name: 'Voice-over-IP (VoIP)', value: '.categories-voip' },
      {
        name: 'Warenwirtschaftssysteme',
        value: '.categories-inventory-management'
      },
      {
        name: 'Web-Zusammenarbeit',
        value: '.categories-web-collaboration'
      },
      { name: 'Web-Konferenzen', value: '.categories-web-conferencing' },
      {
        name: 'Webseiten-Erstellung',
        value: '.categories-website-builder'
      },
      {
        name: 'Wissensmanagement',
        value: '.categories-knowledge-management'
      },
      {
        name: 'Workflow-Management',
        value: '.categories-workflow-management'
      },
      { name: 'Zahlungs-Gateway', value: '.categories-payment-gateway' },
      { name: 'Zeiterfassung', value: '.categories-time-tracking' },
      { name: 'Zeitmanagement', value: '.categories-time-management' }
    ]
  },
  {
    name: 'OSS-Allowlist',
    icon: 'oss-allow-list',
    options: [
      { name: 'Konform', value: 'compliant' }, // Compliant
      { name: 'Nicht konform', value: 'uncompliant' }, // Uncompliant
      { name: 'Komponente Nicht konform', value: 'componentUncompliant' }, // Component Uncompliant
      { name: 'Komponente Ungeprüft', value: 'componentUnchecked' } // Component Unchecked
    ]
  },
  {
    name: 'CVE Security',
    icon: 'cve-security',
    options: [{ name: 'cve-security', value: 0 }]
  }
]

export const validateFilters = ({
  tags,
  searchText,
  cve,
  orderBy,
  orderByCol
}) => {
  if (!['title', 'updatedAt'].includes(orderByCol)) return false
  if (typeof orderBy !== 'boolean') return false
  if (Number(cve) < 0 || Number(cve) > 10) return false
  if (!!searchText && typeof searchText !== 'string') return false
  const flatFilter = filters
    .map(({ options }) => options.map((op) => op.value))
    .flat()
  return tags.every((tag) => flatFilter.includes(tag))
}
