import { createRouter, createWebHistory } from 'vue-router'
import DashboardViewV2 from '../views/DashboardViewV2.vue'
// import DashboardView from '../views/DashboardView.vue'
import ProjectDetailView from '../views/ProjectDetailView.vue'
import AccountManagementV2 from '../views/AccountManagementV2.vue'
import EditorialView from '@/views/EditorialView.vue'
import NotificationCenter from '@/components/NotificationCenter.vue'
import MachbarkeitsNachweise from '@/views/MachbarkeitsNachweise.vue'
import FeatureToggles from '@/components/FeatureToggles.vue'
import axiosinstance from '@/services/axiosInstance'
import { login } from '@/services/loginService'
import store from '@/store'
import ProjectScorecard from '../views/ProjectScorecard.vue'

const routes = [
  {
    path: '',
    alias: '/login_callback',
    name: 'DashboardView',
    component: DashboardViewV2
  },
  {
    path: '/project/:id',
    name: 'ProjectDetailView',
    component: ProjectDetailView
  },
  {
    path: '/admin/feature-toggles',
    name: 'FeatureToggles',
    component: FeatureToggles,
    meta: { featureToggle: 'ftadmin' }
  },
  {
    path: '/admin/editorial-dashboard',
    name: 'EditorialDashboard',
    component: EditorialView,
    meta: {
      requiresAuth: true,
      role: ['editor', 'admin'],
      featureToggle: 'editorial'
    }
  },
  {
    path: '/account-management',
    name: 'AccountManagement',
    component: AccountManagementV2,
    meta: {
      requiresAuth: true,
      featureToggle: 'accountManagement'
    }
  },
  {
    path: '/notifications',
    name: 'NotificationCenter',
    component: NotificationCenter,
    meta: {
      requiresAuth: true,
      featureToggle: 'notifications'
    }
  },
  {
    path: '/machbarkeitsnachweise',
    name: 'Machbarkeitsnachweise',
    component: MachbarkeitsNachweise,
    meta: {
      featureToggle: 'machbarkeitsnachweise'
    }
  },
  {
    path: '/project/:id/scorecard',
    name: 'ProjectScorecard',
    component: ProjectScorecard
  }
]

const history = createWebHistory(process.env.BASE_URL)
const router = createRouter({ history, routes })

router.beforeEach(async(to) => {
  if (
    to.meta?.featureToggle &&
    !window.FEATURE_TOGGLES[to.meta?.featureToggle]
  ) {
    return {
      path: '/',
      query: { error: `Seite ${to.fullPath} ist derzeit nicht aktiviert` }
    }
  }

  if (to.meta?.requiresAuth) {
    const { data: me } = await axiosinstance.get('/me')

    if (me.code !== 200 || !me.userDetails || me.userDetails === '') {
      if (me.code === 302) {
        await login(to.fullPath)
      } else {
        return {
          path: '/',
          query: {
            error: `Sie haben keine ausreichende Berechtigung für den Zugriff auf die Seite ${to.fullPath}`
          }
        }
      }
    } else {
      store.commit('users/authUser', me.userDetails)
    }

    if (to.meta?.role && !to.meta.role.includes(me.userDetails?.role)) {
      return {
        path: '/',
        query: {
          error: `Sie haben keine ausreichende Berechtigung für den Zugriff auf die Seite ${to.fullPath}`
        }
      }
    }
  }
})

export default router
