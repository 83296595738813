<template>
  <div>
    <div id="projDetTop" class="inset-0" />
    <!-- v-if="projectTitle.length > 0" -->
    <ProjectDetailSkelton v-if="!dataLoaded" />
    <div v-if="dataLoaded" :key="dataLoaded">
      <div
        class="flex flex-col xl:flex-row min-w-0 py-8 lg:py-16 bg-banner bg-gray-lighter bg-no-repeat bg-cover min-h-[200px]"
        v-cloak
      >
        <div class="w-1/6 hidden xl:block inset-0"></div>
        <div class="w-full lg:w-5/6 xl:w-3/6">
          <LayoutContainer class="flex gap-x-1 flex-row lg:gap-x-3">
            <div class="lg:w-2/5 xl:w-2/5 flex flex-col gap-y-4 justify-center">
              <div
                class="w-20 h-16 sm:w-48 sm:h-36 mx-auto p-4 bg-gray-light rounded-lg flex items-center justify-center"
              >
                <ProjectLogo
                  :image-url="projectImage"
                  :image-alt="projectTitle"
                />
              </div>
              <ul
                class="h-min mx-auto flex flex-col gap-y-2 sm:gap-y-4 gap-1 bottom-2 left-[20px] justify-bottom xl:hidden"
              >
                <NotificationSetting
                  v-if="FEATURE_TOGGLES.notifications && this.$user?.id"
                />
                <ProductiveAccessSetting
                  v-if="FEATURE_TOGGLES.productiveUse && this.$user?.id"
                />
              </ul>
            </div>
            <!-- v-if="projectTitle.length > 0" -->
            <div class="flex-1 lg:w-3/5 xl:w-3/5 h-full ms-4 lg:ms-0">
              <h1
                class="flex items-center font-poppinsbold font-black tracking-tighter text-xs sm:text-base md:text-4xl lg:text-2xl xl:text-3xl"
              >
                <span>{{ projectTitle }}</span>
              </h1>

              <SimpleDropdown
                v-if="FEATURE_TOGGLES.versioning && projectTags.length > 0"
                :label="`Version: ${selectedOption}`"
                class="mt-6"
              >
                <MenuItem
                  v-for="version in projectTags"
                  :key="version"
                  v-slot="{ active }"
                  @click="onOptionSelect(version.name)"
                >
                  <button
                    class="group text-gray-900 flex w-full items-center rounded-md px-2 py-2 hover:bg-gray-light"
                    :class="[active ? 'bg-gray-light' : '']"
                  >
                    {{ version.name }}
                  </button>
                </MenuItem>
              </SimpleDropdown>
              <SimpleDropdown
                v-else-if="FEATURE_TOGGLES.versioning"
                label="No tags available"
                class="mt-6 text-xs"
              >
              </SimpleDropdown>
            </div>
          </LayoutContainer>
        </div>
        <div class="w-full h-full m-8 hidden xl:block xl:w-2/6 xl:mx-0">
          <ul
            class="h-full flex flex-col gap-y-4 gap-1 bottom-2 left-[20px] text-[12px] sm:text-base justify-bottom"
          >
            <NotificationSetting
              v-if="FEATURE_TOGGLES.notifications && this.$user?.id"
            />
            <ProductiveAccessSetting
              v-if="FEATURE_TOGGLES.productiveUse && this.$user?.id"
            />
          </ul>
        </div>
      </div>

      <!-- Main content -->
      <div class="px-4 2xl:px-0 w-full max-w-custom mx-auto">
        <LayoutContainer
          class="flex flex-col py-6 gap-y-6 lg:py-8 lg:flex-row lg:gap-x-3"
        >
          <div class="w-full lg:w-min" v-if="isCompliant || cveIsCritical">
            <div
              class="w-full inline-flex lg:flex lg:flex-col items-center justify-center lg:justify-end"
            >
              <div class="w-32" v-if="isCompliant">
                <img src="../assets/images/oss-badge.png" />
              </div>
              <div class="w-32" v-if="cveIsCritical">
                <img src="../assets/images/cve-badge.png" />
              </div>
            </div>
          </div>
          <div class="hidden lg:w-min lg:block">
            <ul
              class="min-w-min space-y-6 lg:sticky top-8 text-right pr-8 pt-5"
            >
              <li
                v-if="
                  FEATURE_TOGGLES.scorecardView &&
                  scoreData &&
                  Object.keys(scoreData).length > 0
                "
                class="cursor-pointer font-poppinsbold font-bold whitespace-nowrap text-gray-darkest border-b-4 border-transparent hover:border-red-light transition duration-300 ease-out"
                @click="openModal()"
              >
                Scorecard Details
              </li>
              <li class="cursor-pointer">
                <NavLink
                  href="#details"
                  @click="open('details')"
                  text="Detail-Steckbrief"
                />
              </li>
              <li class="cursor-pointer">
                <NavLink
                  href="#readme"
                  @click="open('readme')"
                  text="Read-Me"
                />
              </li>
              <li class="cursor-pointer">
                <NavLink
                  href="#oss-compliance"
                  @click="open('oss-compliance')"
                  text="OSS Compliance"
                />
              </li>
              <li class="cursor-pointer">
                <NavLink
                  href="#cve-security"
                  @click="open('cve-security')"
                  text="CVE Security"
                />
              </li>
              <li class="cursor-pointer" v-if="FEATURE_TOGGLES.comments">
                <NavLink
                  href="#comments"
                  @click="open('comments')"
                  text="Kommentare"
                />
              </li>
              <li
                v-if="FEATURE_TOGGLES.machbarkeitsnachweise"
                class="cursor-pointer"
              >
                <NavLink
                  href="#einsatz-und-bewertungen"
                  @click="open('einsatz-und-bewertungen')"
                  text="Einsatz & Bewertungen"
                />
              </li>
            </ul>
          </div>

          <TransitionRoot appear :show="isOpen" as="template">
            <Dialog as="div" @close="closeModal" class="relative z-10">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="fixed inset-0 bg-black bg-opacity-25" />
              </TransitionChild>

              <div class="fixed inset-0 overflow-y-auto">
                <div
                  class="flex min-h-full items-center justify-center p-4 text-center"
                >
                  <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95"
                  >
                    <DialogPanel
                      class="w-[20%] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                    >
                      <DialogTitle
                        as="div"
                        class="inline-flex items-center gap-x-4"
                      >
                        <button
                          type="reset"
                          :tabindex="0"
                          class="appearance-none focus:outline-0"
                          @click="closeModal"
                        >
                          <svg
                            class="close"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0" />
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <g id="SVGRepo_iconCarrier">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.2929 4.29289C12.6834 3.90237 13.3166 3.90237 13.7071 4.29289L20.7071 11.2929C21.0976 11.6834 21.0976 12.3166 20.7071 12.7071L13.7071 19.7071C13.3166 20.0976 12.6834 20.0976 12.2929 19.7071C11.9024 19.3166 11.9024 18.6834 12.2929 18.2929L17.5858 13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H17.5858L12.2929 5.70711C11.9024 5.31658 11.9024 4.68342 12.2929 4.29289Z"
                                fill="currentColor"
                              />
                            </g>
                          </svg>
                        </button>
                        <h3
                          class="text-xl font-medium leading-6 text-gray-900 capitalize py-2 font-poppinsbold"
                        >
                          Scorecard Details
                        </h3>
                      </DialogTitle>
                      <div class="mt-2">
                        <div class="modal-body">
                          <div
                            class="flex flex-col items-center border border-slate-900 w-auto px-3"
                          >
                            <img
                              v-if="projectImage !== undefined"
                              :src="projectImage"
                              height="180"
                              width="180"
                              class="mx-auto mt-5 mb-5"
                            />

                            <img
                              v-else
                              src="@/assets/images/logo_box_opencode.jpg"
                              :alt="projectTitle"
                              height="180"
                              width="180"
                              class="mx-auto mt-5 mb-5"
                            />

                            <div
                              class="border border-slate-900 p-4 mt-4 text-center w-full"
                            >
                              <div
                                class="font-poppinsbold text-left text-[#e64363] text-19"
                              >
                                {{ projectTitle }}
                              </div>
                            </div>

                            <div
                              class="border border-slate-900 w-full p-4 mt-4"
                            >
                              <div class="grid grid-flow-col">
                                <p class="text-left font-poppinsbold text-19">
                                  Project Score
                                </p>
                                <span class="font-poppinsbold text-right"
                                  >{{ this.scoreData?.kpis?.value }}/100</span
                                >
                              </div>
                              <div class="h-2 w-full bg-gray-200 mt-2">
                                <div
                                  :style="{
                                    width: `${this.scoreData?.kpis?.value}%`
                                  }"
                                  class="bg-[#129d7a] h-2"
                                ></div>
                              </div>
                            </div>

                            <div
                              class="border border-slate-900 w-full p-4 mt-4"
                            >
                              <div
                                class="grid grid-flow-col m-1"
                                v-for="(item, index) in this.scoreData?.kpis
                                  ?.children"
                                :key="index"
                              >
                                <p
                                  class="text-left font-poppins text-19 mb-1 mt-1"
                                >
                                  {{ item.kpi.name }}
                                </p>
                                <span class="font-poppinsbold text-right"
                                  >{{ item.kpi.value }}/100</span
                                >
                              </div>
                            </div>

                            <div
                              class="border border-slate-900 w-full p-4 mt-4 mb-4"
                            >
                              <button
                                class="bg-blue-500 text-white px-4 py-2 rounded w-full"
                                @click="openExternalLink(this.$route.params.id)"
                              >
                                DETAILS
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </DialogPanel>
                  </TransitionChild>
                </div>
              </div>
            </Dialog>
          </TransitionRoot>

          <div class="w-full lg:flex-1">
            <!-- Warning content -->
            <transition name="fade" mode="out-in">
              <div
                class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 pl-[5px]"
                role="alert"
                v-if="this.yamlNotFound !== 'None'"
              >
                <p class="font-bold">Note:</p>
                <p>
                  Unfortunately, no data could be found for this version.
                  Instead, the data you see corresponds to the most recent
                  information available
                </p>
                <p>
                  Für diese Version konnten leider keine Daten gefunden werden,
                  die Ihnen angezeigten Daten entsprechen stattdessen den
                  aktuellsten verfügbaren Informationen.
                </p>
              </div>
            </transition>

            <ContentAccordion
              title="Detail-Steckbrief"
              id="details"
              ref="details"
              size="lg"
              @click.once="showDetails = true"
            >
              <DetailProfile :tagValue="selectedOption" v-if="showDetails" />
            </ContentAccordion>

            <ContentAccordion
              ref="readme"
              title="Read-Me"
              id="readme"
              size="lg"
              @click.once="showReadme = true"
            >
              <ReadMe :tagValue="selectedOption" v-if="showReadme" />
            </ContentAccordion>

            <ContentAccordion
              title="OSS Compliance"
              id="oss-compliance"
              ref="oss-compliance"
              size="lg"
              @click.once="showOSS = true"
            >
              <OssComplience :tagValue="selectedOption" v-if="showOSS" />
            </ContentAccordion>

            <ContentAccordion
              ref="cve-security"
              title="CVE Security"
              id="cve-security"
              size="lg"
              @click.once="showCVE = true"
            >
              <cveSecurity v-if="showCVE" />
            </ContentAccordion>

            <ContentAccordion
              v-if="FEATURE_TOGGLES.comments"
              title="Comments"
              id="comments"
              ref="comments"
              size="lg"
              @click.once="showComments = true"
              @commentOpen="onCommentOpen"
            >
              <Comments
                :isOpen="isOpenComment"
                :projectImage="projectImage"
                v-if="showComments"
              />
            </ContentAccordion>
            <ContentAccordion
              ref="einsatz-und-bewertungen"
              v-if="FEATURE_TOGGLES.machbarkeitsnachweise"
              title="Einsatz & Bewertungen"
              id="einsatz-und-bewertungen"
              size="lg"
              @click.once="showRatings = true"
            >
              <UseRatings v-if="showRatings" />
            </ContentAccordion>
          </div>
        </LayoutContainer>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue'
import LoadingComp from '@/components/LoadingComp.vue'
import {
  MenuItem,
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from '@headlessui/vue'
import LayoutContainer from '@/components/LayoutContainer.vue'
import NavLink from '@/components/NavLink.vue'
import { mapGetters, mapActions } from 'vuex'
import ContentAccordion from '@/components/ContentAccordion.vue'
import SimpleDropdown from '@/components/SimpleDropdown.vue'
import NotificationSetting from '../components/NotificationSetting.vue'
import ProductiveAccessSetting from '../components/productiveAccessSetting.vue'
import ProjectDetailSkelton from './ProjectDetailSkelton.vue'
import ProjectLogo from '@/components/ProjectDetails/ProjectLogo.vue'
import axiosinstance from '@/services/axiosInstance'

const DetailProfile = defineAsyncComponent({
  loader: () => import('../components/ProjectDetails/detailProfile.vue'),
  loadingComponent: LoadingComp
})
const ReadMe = defineAsyncComponent({
  loader: () => import('../components/ProjectDetails/readMe.vue'),
  loadingComponent: LoadingComp
})
const OssComplience = defineAsyncComponent({
  loader: () => import('../components/ProjectDetails/ossCompliance.vue'),
  loadingComponent: LoadingComp
})
const cveSecurity = defineAsyncComponent({
  loader: () => import('../components/ProjectDetails/cveSecurity.vue'),
  loadingComponent: LoadingComp
})
const Comments = defineAsyncComponent({
  loader: () => import('../components/ProjectDetails/commentSection.vue'),
  loadingComponent: LoadingComp
})
const UseRatings = defineAsyncComponent({
  loader: () => import('../components/ProjectDetails/useAndRatings.vue'),
  loadingComponent: LoadingComp
})

export default {
  name: 'ProjectDetailView',
  components: {
    DetailProfile,
    ReadMe,
    OssComplience,
    cveSecurity,
    Comments,
    UseRatings,
    LayoutContainer,
    NavLink,
    ContentAccordion,
    SimpleDropdown,
    MenuItem,
    NotificationSetting,
    ProjectDetailSkelton,
    ProjectLogo,
    ProductiveAccessSetting,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle
  },
  data() {
    return {
      FEATURE_TOGGLES: window.FEATURE_TOGGLES,
      showDetails: false,
      showReadme: false,
      showOSS: false,
      showCVE: false,
      showComments: false,
      showRatings: false,
      productiveUseVisible: false,
      selectedOption: 'Select Tag',
      isActive: false,
      isOpenComment: false,
      yamlNotFound: 'None',
      dataLoaded: false,
      cveRatings: 0,
      isOpen: ref(false),
      scoreData: {}
    }
  },
  async mounted() {
    this.getLicenses().then(() => {
      const ids = this.licenses.map((l) => l.id)
      return this.getLicenseObligations(ids)
    })

    this.getQuestions({
      projectId: this.$route.params.id
    })
    this.getNotificationTypes({
      projectId: this.$route.params.id
    })

    await this.callActions()

    const ref = this.$route.hash?.replace('#', '')
    if (this.$refs[ref]) {
      this.open(ref)
    } else {
      window.scrollTo({ top: 0 })
    }
  },
  methods: {
    ...mapActions({ updatedTree: 'projectPage/getProjectDetailsByTag' }),
    ...mapActions({ getLicenses: 'license/getLicenses' }),
    ...mapActions({ getLicenseObligations: 'license/getLicenseObligations' }),
    ...mapActions({ getQuestions: 'questionaries/getQuestions' }),
    ...mapActions({
      getNotificationTypes: 'notification/getUserNotifictaionSettings'
    }),
    ...mapActions({ getProjectDetailById: 'projectPage/getProjectDetailById' }),
    ...mapActions({
      getProjectDeatilOssCompilance: 'projectPage/getProjectDeatilOssCompilance'
    }),
    ...mapActions({
      getProjectDeatilCVESecurity: 'projectPage/getProjectDeatilCVESecurity'
    }),
    ...mapActions({ getProjectTagList: 'projectPage/getProjectTagList' }),
    productiveUseToggle() {
      this.productiveUseVisible = !this.productiveUseVisible
    },
    ...mapActions({ scorecard: 'projectPage/getProjectScorecardDetails' }),
    onOptionSelect(val) {
      this.selectedOption = val
      this.updatedTree({ projectId: this.$route.params.id, tagName: val }).then(
        () => {
          this.checkPublicCodeYmlFile()
        }
      )
    },
    onCommentOpen(e) {
      this.isOpenComment = !this.isOpenComment
    },
    checkPublicCodeYmlFile() {
      if (
        this.updatedTreeData[this.$route.params.id]?.publicCodeYaml.content ===
          null ||
        this.updatedTreeData[this.$route.params.id]?.publicCodeYaml.content ===
          undefined
      ) {
        const indexValue = this.getTags[this.$route.params.id].findIndex(
          (obj) => obj.name === this.selectedOption
        )
        let changedTagId = this.getTags[this.$route.params.id][indexValue - 1]
        if (changedTagId === undefined) {
          changedTagId = this.getTags[this.$route.params.id][indexValue + 1]
        }
        this.yamlNotFound = this.selectedOption
        setTimeout(() => {
          this.yamlNotFound = 'None'
        }, 5000)
        this.selectedOption = changedTagId.name
        this.updatedTree({
          projectId: this.$route.params.id,
          tagName: changedTagId.name
        })
      }
    },
    async callActions() {
      this.getProjectDeatilOssCompilance(this.$route.params.id)
      this.getProjectDeatilCVESecurity(this.$route.params.id)
      this.getProjectTagList(this.$route.params.id)
      await this.getProjectDetailById(this.$route.params.id)
      await this.scorecard(this.$route.params.id)
      this.dataLoaded = true
    },
    open(ref) {
      this.$refs[ref].isOpen = true
      this.$refs[ref].$el.click()
      this.$router.replace({ hash: '#' + ref })
    },
    openModal() {
      this.isOpen = true
    },
    closeModal() {
      this.isOpen = false
    },
    async openExternalLink(projectId) {
      let link
      if (this.authUser?.id === undefined) {
        // non logged in user
        link = `https://dev-kpi.o4oe.de/projects/${projectId}`
      } else {
        // logged in user
        const getbValue = 'https://sl.dev.o4oe.de/api/transfer-session/1'
        const { data: bValue } = await axiosinstance.get(getbValue)
        link = `https://dev-kpi.o4oe.de/projects/${projectId}?b=${bValue.b}`
      }
      window.open(link, '_blank')
    },
    checkScorecardDetails() {
      this.scorecard(this.$route.params.id)
      this.scoreData = this.scorecardDetails[this.$route.params.id]?.data
    }
  },
  created() {},
  computed: {
    ...mapGetters({ projectDetail: 'projectPage/projectData' }),
    ...mapGetters({ licenses: 'license/licenses' }),
    ...mapGetters({ getTags: 'projectPage/tagsData' }),
    ...mapGetters({ updatedTreeData: 'projectPage/tagData' }),
    ...mapGetters({ productiveAccessData: 'projectPage/productiveAccess' }),
    ...mapGetters({ cveData: 'projectPage/cveSecurityData' }),
    ...mapGetters({ projectList: 'projectPage/projectList' }),
    ...mapGetters({ scorecardDetails: 'projectPage/scorecardDetails' }),
    ...mapGetters({ authUser: 'users/authUser' }),

    getProject() {
      if (this.projectDetail[this.$route.params.id]) {
        return this.projectDetail[this.$route.params.id].projectDetails
      }

      return undefined
    },
    projectTitle() {
      return this.getProject?.title
    },
    projectImage() {
      return this.getProject?.logo || undefined
    },
    projectTags() {
      return this.getTags[this.$route.params.id] || []
    },
    isCompliant() {
      return this.getProject?.legal === 'compliant'
    },
    cveIsCritical() {
      const cveScore =
        this.cveData !== null && this.cveData[this.$route.params.id] !== null
          ? this.cveData[this.$route.params.id]?.[0]?.severity
          : 0
      return cveScore > 7
    }
  },
  watch: {
    scorecardDetails: {
      handler(newVal, oldVal) {
        if (newVal && newVal[this.$route.params.id] === undefined) {
          this.checkScorecardDetails()
        } else if (
          Object.keys(newVal && newVal[this.$route.params.id]).length > 0
        ) {
          this.scoreData = newVal && newVal[this.$route.params.id]?.data
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="postcss">
/*
.version:hover .dropdown-content {
  display: block;
}*/

.max-w-custom {
  max-width: 1950px;
  /* You can adjust this value to make it wider */
}

[v-cloak] {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

html {
  scroll-padding-top: 12px;
}

.down-cursor {
  cursor: url('../assets/icons/down-black-curve-arr.png'), auto;
}

.up-cursor {
  cursor: url('../assets/icons/up-black-curve-arr.png'), auto;
}

.menu-box {
  transition: visibility 0s, opacity 0.35s ease-in;
}

.show {
  visibility: visible;
  opacity: 1;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.close {
  @apply w-6 h-6 text-icon rotate-180;
  @apply hover:text-opacity-70 drop-shadow-lg hover:drop-shadow cursor-pointer;
}
</style>
