<template>
  <div class="flex flex-col min-h-[100vh] w-full">
    <NavBar />
    <!-- <TheHeader /> -->
    <div class="flex-1">
      <router-view />
    </div>
    <Footer />
  </div>
</template>
<script setup>
// import TheHeader from './layouts/TheHeader.vue'
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/NavBar/NavBar.vue'
import { watch } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()

watch(
  () => route.fullPath,
  () => {
    window.versioningTimer.runTimersCheck()
  }
)
</script>

<style>
body {
  margin: 0;
  height: 100%;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}
</style>
