<template>
  <div class="w-full h-full flex items-center justify-center">
    <span class="sr-only">{{ alt }}</span>
    <img v-if="src" :src="src" :alt="alt" @error="src = false" />
    <img v-else src="@/assets/images/logo_box_opencode.jpg" :alt="alt" />
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps(['imageUrl', 'imageAlt'])
const src = ref(props.imageUrl)
const alt = ref(props.imageAlt)
</script>

<style scoped lang="postcss">
img {
  @apply relative;
  @apply mix-blend-multiply w-full h-full aspect-auto object-contain;
}
</style>
