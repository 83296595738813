export const mutations = {
  pushNotification(state, notifications) {
    state.notifications = notifications
  },
  insertNotificationTypes(state, types) {
    state.notificationTypes = types
  },
  insertNotificationTypesPerUser(state, types) {
    state.notificationTypesPerUser = types
  },
  updateNotificationCount(state, types) {
    state.notificationCount = types
  }
}
