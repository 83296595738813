<template>
  <div class="flex items-center gap-x-2">
    <RatingStars :rating="rating" />
    <div v-if="ratings" class="text-sm text-gunmetal">{{ ratings }}</div>
  </div>
</template>

<script>
import RatingStars from './RatingStars.vue'

export default {
  components: {
    RatingStars
  },
  props: {
    rating: {
      type: Number,
      required: true
    },
    ratings: {
      type: Number,
      default: 0
    }
  }
}
</script>
