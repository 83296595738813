/* eslint-disable no-return-assign */
export const mutations = {
  insertUser(state, user) {
    state.list[user.id] = user
  },
  insertUsers(state, data) {
    data.data.forEach((user) => {
      state.list[user.id] = user
    })
  },
  emptyUserList(state) {
    state.list = {}
  },
  insertMe(state, user) {
    state.list[user.id] = user
    state.me = user
  },
  userDetails(state, userDetails) {
    if (!state.userDetails) {
      state.userDetails = {}
    }
    state.userDetails = userDetails.userDetails
  },
  authUser(state, user) {
    if (!state.authUser) {
      state.authUser = null
    }
    state.authUser = user
  },
  setAnonymous(state, isAnonymous) {
    state.isAnonymous = isAnonymous
  }
}
