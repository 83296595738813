<template>
  <li
    v-if="notificationTypes.length"
    class="flex h-6 flex-row lg:flex-col relative"
  >
    <a
      class="inline-flex items-center px-2 gap-1 font-poppins text-[10px] sm:text-base"
      href="javascript:void(0)"
      @click="toggleNotification = !toggleNotification"
    >
      <img
        class="w-4 sm:w-6"
        src="../assets/icons/notification-on-solid-svgrepo-com.svg"
      />
      Benachrichtigung</a
    >
    <ul
      class="absolute top-8 lg:-translate-x-4 z-20 w-[220px] h-[200px] font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
      v-if="toggleNotification"
    >
      <li
        class="notification-chkbx w-full border-gray-200 rounded-t-lg dark:border-gray-600 pt-[10px]"
        v-for="(type, index) in notificationTypes"
        :key="index"
      >
        <div class="flex items-center pl-0">
          <label class="form-control">
            <input
              id="vue-checkbox"
              type="checkbox"
              :value="type.id"
              :checked="type.active"
              :disabled="FEATURE_TOGGLES.keycloak && !authUser"
              @change="onSelect($event)"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            />
            <span class="ml-[5px]">{{ type.name }}</span>
          </label>
          <!--
          <input
            id="vue-checkbox"
            type="checkbox"
            :value="type.id"
            :checked="type.active"
            @change="onSelect($event)"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          />
          <label
            for="vue-checkbox"
            class="w-full py-3 ml-2 text-gray-900 dark:text-gray-300 font-poppins text-[18px]"
            >{{ type.name }}</label
          >-->
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'NotificationSetting',
  components: {},
  data() {
    return {
      toggleNotification: false,
      FEATURE_TOGGLES: window.FEATURE_TOGGLES
    }
  },
  computed: {
    ...mapGetters({ notificationTypes: 'notification/notificationTypes' }),
    ...mapGetters({ authUser: 'users/authUser' })
  },
  created() {},
  methods: {
    ...mapActions({
      updateUserNotifictaionSettings:
        'notification/updateUserNotifictaionSettings'
    }),
    ...mapActions({
      getNotificationTypes: 'notification/getUserNotifictaionSettings'
    }),
    onSelect(e) {
      this.updateUserNotifictaionSettings({
        projectId: this.$route.params.id,
        notificationTypeId: e.target.value,
        active: e.target.checked,
        userId: this.$user?.id
      })
      setTimeout(() => {
        this.getNotificationTypes({
          projectId: this.$route.params.id,
          userId: this.$user?.id
        })
      }, 1000)
    }
  }
}
</script>

<style>
.form-control {
  font-size: 18px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  margin: 3px 3px;
  margin-left: 10px;
}

.notification-chkbx input[type='checkbox'] {
  cursor: pointer;
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: white;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #e0004b;
  width: 25px;
  height: 25px;
  border: 2px solid black;
  border-radius: 0;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

.notification-chkbx input[type='checkbox']::before {
  content: '';
  width: 20px;
  height: 20px;
  /*clip-path: polygon(14% 41%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 40% 62%);*/
  clip-path: polygon(
    30% 70%,
    90% 20%,
    92% 32%,
    59% 60%,
    28% 85%,
    8% 49%,
    16% 39%
  );
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #e0004b;
}

.notification-chkbx input[type='checkbox']:checked::before {
  transform: scale(1);
}

.notification-chkbx input[type='checkbox']:focus {
  outline: none;
  outline-offset: max(2px, 0.15em);
}

.notification-chkbx input[type='checkbox']:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}
</style>
