<!-- eslint-disable no-tabs -->
<template>
  <div class="absolute -mt-14 font-normal" :class="customClass">
    <div
      class="border-solid relative bg-[#E0004B] border-0 mb-3 block z-50 font-inherit leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
    >
      <div class="text-white p-3">
        <slot></slot>
      </div>

      <span class="pic arrow-down"></span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ToolTip',
  props: ['customClass']
}
</script>
